import { File } from "src/api/types.generated";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import { Nullable } from "src/shared/utils/typescript";

export enum Field {
  // Hacker Application
  SHORT_NAME = "short_name",
  SHORT_LOCATION = "short_location",
  SHORT_SCHOOL = "short_school",
  SHORT_PROGRAM = "short_program",
  SHORT_GRAD_YEAR = "short_grad_year",
  SHORT_GRAD_LEVEL = "short_grad_level",
  LONG_LINKS = "long_links",

  // ACCOUNT
  // SAVED_EVENTS = "schedule_saved_events",

  // RSVP
  LEGAL_NAME = "rsvp_legal_name",
  PREFERRED_NAME = "rsvp_preferred_name",
  PRONOUNS = "rsvp_preferred_pronouns",
  PHONE_NUMBER = "rsvp_phone_number",
  WATERLOO_STUDENT_ID_NUMBER = "rsvp_waterloo_student_id",
  EMERGENCY_CONTACT_NAME = "rsvp_emergency_contact",
  EMERGENCY_CONTACT_RELATIONSHIP = "rsvp_emergency_contact_relationship",
  EMERGENCY_CONTACT_PHONE_NUMBER = "rsvp_emergency_contact_phone_number",
  EMERGENCY_CONTACT_EMAIL = "rsvp_emergency_contact_email",
  MLH_SHARE_CONSENT = "rsvp_mlh_share_consent",
  ACCESSIBILITY_NEEDS = "rsvp_accessibility_needs",
  SHIRT_TYPE = "rsvp_swag_shirt_type",
  SHIRT_SIZE = "rsvp_swag_shirt_size",
  SWAG_SHIRT_ACCOMODATIONS = "rsvp_swag_shirt_accomodations",
  FOOD_DIETARY_RESTRICTIONS = "rsvp_food_dietary_restrictions",

  // Not used
  // ADDRESS_LINE_ONE = "address_1",
  // ADDRESS_LINE_TWO = "address_2",
  // CITY = "city",
  // PROVINCE_STATE_REGION = "province_state_region",
  // COUNTRY = "country",
  // POSTAL_CODE = "postal_code",
  // SWAG_PICKUP = "swag_pickup",
  // RECORDING_CONSENT = "recording_consent",
  // MLH_EMAIL_CONSENT = "mlh_email_consent",
  // COMPANY_INTEREST = "networking",

  // RSVP Event
  PROJECT_STREAM = "rsvp_project_stream",
  TECH_TALKS = "rsvp_tech_talks",
  MEETUPS = "rsvp_meetups",
  GITHUB = "networking_github_link",
  INSTAGRAM = "networking_instagram_link",
  FACEBOOK = "networking_facebook_link",
  TWITTER = "networking_twitter_link",
  LINKEDIN = "networking_linkedin_link",
  DISCORD_TAG = "networking_discord_tag",
  TIKTOK = "networking_tiktok_link",

  // RSVP Career
  RECRUITMENT_CONSENT = "rsvp_career_recruitment_consent",
  RESUME = "rsvp_career_resume",
  CANADIAN_CITIZEN = "rsvp_career_canadian_citizen",
  PERMANENT_RESIDENT = "rsvp_career_permanent_resident",
  LOOKING_FOR_INTERNSHIPS = "rsvp_career_looking_for_internships",
  LOOKING_FOR_FULLTIME = "rsvp_career_looking_for_fulltime",
  SPONSOR_INTEREST = "rsvp_sponsor_interest",
  COFFEE_CHAT = "rsvp_career_coffee_chat",

  // RSVP Confirmation
  AGE_18 = "rsvp_consent_age_18",
  TERMS_CONDITIONS = "rsvp_consent_terms_conditions",
  GUARDIAN_NAME = "rsvp_consent_guardian_name",
  GUARDIAN_CONTACT = "rsvp_consent_guardian_contact",
  WAIVER_CONSENT = "rsvp_consent_waiver",

  // RSVP TRAVEL STAGE
  LOCATION = "rsvp_travel_location",
  TRAVEL_OPTION = "rsvp_travel_method",
  ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT = "rsvp_entry_eligibility_acknowledgement",
  REQUIRES_VISA = "rsvp_travel_requires_visa",
  VISA_ACKNOWLEDGEMENT = "rsvp_travel_visa_acknowledgement",

  // RSVP Page Stages
  PERSONAL_RSVP_STAGE = "personal_rsvp_stage",
  TRAVEL_RSVP_STAGE = "travel_rsvp_stage",
  EVENT_RSVP_STAGE = "event_rsvp_stage",
  CAREER_RSVP_STAGE = "career_rsvp_stage",
  CONFIRMATION_RSVP_STAGE = "confirmation_rsvp_stage",
  RSVP_DEADLINE = "rsvp_deadline",

  // Day-Of-Event
  // INTERESTS = "interests",
  DISCORD_ID = "discord_id",

  // Onboarding
  // ONBOARDING_PROGRESS = "onboarding_progress",

  // Fun metadata
  // travel form
  TRAVEL_PHONE_NUMBER = "rsvp_travel_phone_number",
  BUS_COORDINATOR_INTEREST = "rsvp_travel_bus_coordinator_interest",

  FRIDAY_FLIGHT_NUMBER = "rsvp_travel_friday_flight_number",
  FRIDAY_FLIGHT_AIRLINE = "rsvp_travel_friday_flight_airline",
  FRIDAY_FLIGHT_ARRIVAL_TIME = "rsvp_travel_friday_flight_arrival_time",
  SUNDAY_FLIGHT_NUMBER = "rsvp_travel_sunday_flight_number",
  SUNDAY_FLIGHT_AIRLINE = "rsvp_travel_sunday_flight_airline",
  SUNDAY_FLIGHT_DEPARTURE_TIME = "rsvp_travel_sunday_flight_departure_time",

  FLIGHT_CURRENCY = "rsvp_travel_flight_payment_currency",
  FLIGHT_AMOUNT = "rsvp_travel_flight_payment_amount",
  REIMBURSEMENT_METHOD = "rsvp_travel_reimbursement_method",
  REIMBURSEMENT_RECIPIENT_NAME = "rsvp_travel_reimbursement_recipient_name",
  REIMBURSEMENT_RECIPIENT_ADDRESS = "rsvp_travel_reimbursement_recipient_address",
  REIMBURSEMENT_RECIPIENT_ETRANSFER = "rsvp_travel_reimbursement_etransfer_email",
  REIMBURSEMENT_RECIPIENT_PAYPAL = "rsvp_travel_reimbursement_paypal_email",
  REIMBURSEMENT_ACKNOWLEDGEMENT = "rsvp_travel_reimbursement_acknowledgement",

  FLIGHT_REIMBURSEMENT = "rsvp_travel_flight_reimbursement",
  DEPARTURE_AIRPORT = "rsvp_travel_departure_airport",
  ARRIVAL_AIRPORT = "rsvp_travel_arrival_airport",

  VISA_TRACKING = "rsvp_travel_visa_tracking_number",
  SHARE_EMAIL = "rsvp_travel_share_email_consent",

  TRAVEL_RECEIPT = "rsvp_travel_receipt_upload",

  // Networking
  BIOGRAPHY = "networking_biography",
  INTERESTS = "interests",
}

export enum HackerGrad {
  LEVEL_OF_STUDY = "level_of_study",
  GRADUATING_YEAR = "graduating_year",
}

export enum PageStage {
  NOT_STARTED = "not_started",
  COMPLETED = "completed",
}

export type RSVPData = {
  // Personal Information
  [Field.LEGAL_NAME]: Nullable<string>;
  [Field.PREFERRED_NAME]: Nullable<string>;
  [Field.PRONOUNS]: Nullable<string[]>;
  [Field.PHONE_NUMBER]: Nullable<string>;
  [Field.WATERLOO_STUDENT_ID_NUMBER]: Nullable<string>;
  [Field.EMERGENCY_CONTACT_NAME]: Nullable<string>;
  [Field.EMERGENCY_CONTACT_RELATIONSHIP]: Nullable<string>;
  [Field.EMERGENCY_CONTACT_PHONE_NUMBER]: Nullable<string>;
  [Field.EMERGENCY_CONTACT_EMAIL]: Nullable<string>;
  [Field.MLH_SHARE_CONSENT]: Nullable<boolean>; // if the hacker opted into sharing application/registration info with MLH
  [Field.ACCESSIBILITY_NEEDS]: Nullable<string>;
  [Field.SHIRT_TYPE]: Nullable<string>;
  [Field.SHIRT_SIZE]: Nullable<string>;
  [Field.SWAG_SHIRT_ACCOMODATIONS]: Nullable<string>;
  [Field.FOOD_DIETARY_RESTRICTIONS]: Nullable<string[]>;

  // [Field.ADDRESS_LINE_ONE]: Nullable<string>;
  // [Field.ADDRESS_LINE_TWO]: Nullable<string>;
  // [Field.CITY]: Nullable<string>;
  // [Field.PROVINCE_STATE_REGION]: Nullable<string>;
  // [Field.COUNTRY]: Nullable<string>;
  // [Field.POSTAL_CODE]: Nullable<string>;

  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // [Field.RECORDING_CONSENT]: Nullable<boolean>;
  // [Field.MLH_EMAIL_CONSENT]: Nullable<boolean>; // if the hacker opted into receiving emails from MLH

  // Travel
  [Field.LOCATION]: Nullable<string>;
  [Field.TRAVEL_OPTION]: Nullable<string>;
  [Field.ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT]: Nullable<boolean>;
  [Field.REQUIRES_VISA]: Nullable<boolean>;
  [Field.VISA_ACKNOWLEDGEMENT]: Nullable<boolean>;

  // RSVP Event
  [Field.PROJECT_STREAM]: Nullable<string>;
  [Field.TECH_TALKS]: Nullable<string[]>;
  [Field.MEETUPS]: Nullable<string[]>;
  [Field.GITHUB]: Nullable<string>;
  [Field.INSTAGRAM]: Nullable<string>;
  [Field.FACEBOOK]: Nullable<string>;
  [Field.TWITTER]: Nullable<string>;
  [Field.LINKEDIN]: Nullable<string>;
  [Field.DISCORD_TAG]: Nullable<string>;
  [Field.TIKTOK]: Nullable<string>;
  [Field.BIOGRAPHY]: Nullable<string>;

  // RSVP Career
  [Field.RECRUITMENT_CONSENT]: Nullable<boolean>; // consent to prpovide data to sponsors for recruitment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Field.RESUME]: Nullable<any>;
  [Field.CANADIAN_CITIZEN]: Nullable<boolean>;
  [Field.PERMANENT_RESIDENT]: Nullable<boolean>; // permanent resident of canada
  [Field.LOOKING_FOR_INTERNSHIPS]: Nullable<boolean>; // looking for internship opportunities
  [Field.LOOKING_FOR_FULLTIME]: Nullable<boolean>; // looking for full-time opportunities
  [Field.SPONSOR_INTEREST]: Nullable<string[]>;
  [Field.COFFEE_CHAT]: Nullable<string[]>;

  // RSVP Confirmation
  [Field.AGE_18]: Nullable<boolean>;
  [Field.TERMS_CONDITIONS]: Nullable<boolean>; // agree to terms and conditions
  [Field.GUARDIAN_NAME]: Nullable<string>;
  [Field.GUARDIAN_CONTACT]: Nullable<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Field.WAIVER_CONSENT]: Nullable<any>;

  // Account
  // [Field.SAVED_EVENTS]: Nullable<string[]>;
  // [Field.INTERESTS]: Nullable<string[]>;
  [Field.DISCORD_TAG]: Nullable<string>;
  // temporary
  [Field.RSVP_DEADLINE]: Nullable<string>;

  [Field.PERSONAL_RSVP_STAGE]: Nullable<string>;
  [Field.TRAVEL_RSVP_STAGE]: Nullable<string>;
  [Field.EVENT_RSVP_STAGE]: Nullable<string>;
  [Field.CAREER_RSVP_STAGE]: Nullable<string>;
  [Field.CONFIRMATION_RSVP_STAGE]: Nullable<string>;
};

export type TravelFormData = {
  [Field.TRAVEL_PHONE_NUMBER]: Nullable<string>;
  [Field.BUS_COORDINATOR_INTEREST]: Nullable<boolean>;

  [Field.FRIDAY_FLIGHT_AIRLINE]: Nullable<string>;
  [Field.FRIDAY_FLIGHT_ARRIVAL_TIME]: Nullable<string>;
  [Field.FRIDAY_FLIGHT_NUMBER]: Nullable<string>;

  [Field.SUNDAY_FLIGHT_AIRLINE]: Nullable<string>;
  [Field.SUNDAY_FLIGHT_DEPARTURE_TIME]: Nullable<string>;
  [Field.SUNDAY_FLIGHT_NUMBER]: Nullable<string>;

  [Field.FLIGHT_CURRENCY]: Nullable<string>;
  [Field.FLIGHT_AMOUNT]: Nullable<number>;
  [Field.REIMBURSEMENT_METHOD]: Nullable<string>;
  [Field.REIMBURSEMENT_RECIPIENT_NAME]: Nullable<string>;
  [Field.REIMBURSEMENT_RECIPIENT_ADDRESS]: Nullable<string>;
  [Field.REIMBURSEMENT_RECIPIENT_ETRANSFER]: Nullable<string>;
  [Field.REIMBURSEMENT_RECIPIENT_PAYPAL]: Nullable<string>;
  [Field.REIMBURSEMENT_ACKNOWLEDGEMENT]: Nullable<boolean>;

  [Field.FLIGHT_REIMBURSEMENT]: Nullable<string>;
  [Field.DEPARTURE_AIRPORT]: Nullable<string>;
  [Field.ARRIVAL_AIRPORT]: Nullable<string>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Field.TRAVEL_RECEIPT]: Nullable<any>;
  [Field.VISA_TRACKING]: Nullable<string>;
  [Field.SHARE_EMAIL]: Nullable<boolean>;
};

export enum HackerStage {
  CHECKED_IN = "checked_in",
  CONFIRMED = "confirmed",
  WITHDRAWN = "withdrawn",
  WAITLISTED = "waitlisted",
  NOT_ACCEPTED = "not_accepted",
  ACCEPTED = "accepted",
  EXPIRED = "expired",
  AUTO_ACCEPTED = "auto-accepted",
}

export type TAnswer = Nullable<
  | string
  | string[]
  | number
  | Record<string, string>
  | boolean
  | JSON
  | HackerAPIFile
  | File
>;

export type TAnswers = RSVPData & TravelFormData & Record<string, TAnswer>;
