/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../types.generated";
export type UpdateClaimMutationVariables = Types.Exact<{
  updatedData: Types.ClaimUpdateInput;
}>;

export type UpdateClaimMutation = {
  updateClaim: Pick<Types.Claim, "id" | "name"> & {
    stage: Pick<Types.Stage, "slug">;
    fields: Array<
      Pick<
        Types.FieldWithAnswer,
        "id" | "field_type" | "slug" | "valid_values" | "updated_at"
      > & {
        answer?: Types.Maybe<
          Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
        >;
      }
    >;
  };
};

export const UpdateClaimDocument = gql`
  mutation UpdateClaim($updatedData: ClaimUpdateInput!) {
    updateClaim(data: $updatedData) {
      id
      name
      stage {
        slug
      }
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useUpdateClaimMutation__
 *
 * To run a mutation, you first call `useUpdateClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClaimMutation, { data, loading, error }] = useUpdateClaimMutation({
 *   variables: {
 *      updatedData: // value for 'updatedData'
 *   },
 * });
 */
export function useUpdateClaimMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClaimMutation,
    UpdateClaimMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateClaimMutation,
    UpdateClaimMutationVariables
  >(UpdateClaimDocument, baseOptions);
}
export type UpdateClaimMutationHookResult = ReturnType<
  typeof useUpdateClaimMutation
>;
export type UpdateClaimMutationResult =
  ApolloReactCommon.MutationResult<UpdateClaimMutation>;
export type UpdateClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClaimMutation,
  UpdateClaimMutationVariables
>;
