import { TimePrecision, DateInput } from "@blueprintjs/datetime";
import { Spacer, Flex } from "@hackthenorth/north";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { stringToDate, dateToString } from "src/shared/utils/date";

import Icon from "../Icon";

type TDateTimePickerProps = {
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  error?: React.ReactNode;
  placeholder?: string;
};

const DateTimePicker: React.FC<TDateTimePickerProps> = ({
  value,
  onChange,
  error,
  placeholder = "",
}) => {
  const [candidate, setCandidate] = useState(stringToDate(value));
  const internalOnChange = useCallback((date: Date) => setCandidate(date), []);

  const handlePopoverClose = useCallback(() => {
    if (candidate !== null) onChange(dateToString(candidate));
  }, [onChange, candidate]);

  return (
    <>
      <Spacer height={8} />
      <StyledDateInput
        value={candidate}
        onChange={internalOnChange}
        timePrecision={TimePrecision.MINUTE}
        parseDate={stringToDate}
        formatDate={(d: Date) =>
          d.toLocaleString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        }
        showActionsBar
        canClearSelection
        closeOnSelection={false}
        error={error as boolean}
        popoverProps={{
          onClose: handlePopoverClose,
        }}
        placeholder={placeholder}
      />
      {error && <Error error={error} />}
    </>
  );
};

export default DateTimePicker;

type TErrorProps = {
  error: string | React.ReactNode;
};

export const Error: React.FC<TErrorProps> = ({ error }) => (
  <Container>
    <Icon name="error-exclamation" height={16} width={16} fill="#d95858" />
    {error}
  </Container>
);

const StyledDateInput = styled(DateInput)<{ error: boolean }>`
  & input {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5rem;
    border-radius: 0.375rem;
    border: 1px solid
      ${({ error }) =>
        error ? "rgba(209, 213, 219, 1)" : "rgba(209, 213, 219, 1)"};
    color: rgba(31, 41, 55, 1);
    --tw-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding: 20px 16px;
  }

  & input:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-offset-width: 0px;
    --tw-ring-color: rgba(117, 165, 238, 0.5);
    border: 1px solid rgba(117, 165, 238, 1);
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .bp3-popover-target {
    width: 100%;
  }
`;

const Container = styled(Flex).attrs({ align: "center", row: true })`
  font-size: 12px;
  margin-top: 4px;
  gap: 8px;
  --tw-text-opacity: 1;
  color: rgba(217, 88, 88, var(--tw-text-opacity));
`;
