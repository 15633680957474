import React, { useCallback, useEffect, useState } from "react";
import AnimatedNumbers from "react-animated-numbers";
import styled, { keyframes, css } from "styled-components";

import { useGetSignageCountStatsQuery } from "./graphql/getSignageCountStats.generated";

type StatsState = {
  currentStats: string[];
  statQueue: string[];
};

const StatDisplays = () => {
  const { refetch } = useGetSignageCountStatsQuery({});

  const [stats, setStats] = useState<{ [key: string]: number }>({});
  const [statsState, setStatsState] = useState<StatsState>({
    currentStats: [],
    statQueue: [],
  });

  const [flippingIndex, setFlippingIndex] = useState<number | null>(null);
  const [animatedNumberTrigger, setAnimatedNumberTrigger] = useState<number[]>([
    0, 0, 0,
  ]);
  const [lastClickTime, setLastClickTime] = useState<number>(Date.now());

  useEffect(() => {
    const fetchStatData = () => {
      refetch()?.then((result) => {
        const updatedStats = result?.data?.signageStats as any;

        setStats(updatedStats);

        // Ensure currentStats is updated correctly
        setStatsState((prevStatsState) => {
          const newCurrentStats = [...prevStatsState.currentStats];
          const newStatQueue = [...prevStatsState.statQueue];

          for (const key in updatedStats) {
            if (key === "__typename") {
              continue;
            }

            if (newCurrentStats.length < 3 && !newCurrentStats.includes(key)) {
              newCurrentStats.push(key);
            } else if (
              !newCurrentStats.includes(key) &&
              !newStatQueue.includes(key)
            ) {
              newStatQueue.push(key);
            }
          }

          return {
            currentStats: newCurrentStats,
            statQueue: newStatQueue,
          };
        });
      });

      // Set up the next fetch with proper cleanup
      setTimeout(fetchStatData, 8000);
    };

    fetchStatData();

    return () => {
      clearTimeout(); // Clear any pending timeouts on unmount
    };
  }, [refetch]);

  const updateStat = (index: number) => {
    setStatsState((prevStatsState) => {
      const newCurrentStats = [...prevStatsState.currentStats];
      const newStatQueue = [...prevStatsState.statQueue];

      if (newStatQueue.length === 0) {
        return prevStatsState; // No items in the queue, return the same state
      }

      const nextStat = newStatQueue.shift() ?? "";

      // Replace the stat in currentStats with the next one from the queue
      newCurrentStats[index] = nextStat;

      // Add the replaced stat to the end of the queue
      if (nextStat !== prevStatsState.currentStats[index]) {
        newStatQueue.push(prevStatsState.currentStats[index]);
      }

      return {
        currentStats: newCurrentStats,
        statQueue: newStatQueue,
      };
    });
  };

  const handleBoxClick = useCallback(
    (index: number) => {
      if (flippingIndex !== null) {
        return; // Prevent flipping multiple boxes at once
      }

      setFlippingIndex(index);
      setLastClickTime(Date.now());

      setTimeout(() => {
        updateStat(index);
      }, 500);

      setTimeout(() => {
        setFlippingIndex(null);
        setAnimatedNumberTrigger((prev) => {
          // Trigger a re-render of the AnimatedNumbers component
          const newTrigger = [...prev];
          newTrigger[index] += 1;
          return newTrigger;
        });
      }, 1000);
    },
    [flippingIndex]
  );

  useEffect(() => {
    const currentTime = Date.now();
    const timeSinceLastClick = currentTime - lastClickTime;

    if (timeSinceLastClick >= 12000) {
      // If 12 seconds have passed, trigger a random box click
      const randomIndex = Math.floor(Math.random() * 3);
      handleBoxClick(randomIndex);
    }

    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastClick = currentTime - lastClickTime;

      if (timeSinceLastClick >= 12000) {
        // If 12 seconds have passed, trigger a random box click
        const randomIndex = Math.floor(Math.random() * 3);
        handleBoxClick(randomIndex);
      }
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [handleBoxClick, lastClickTime]);

  return (
    <StatWrapper>
      <StatTitle>
        Check stats for Hack the North 2024{" "}
        <span role="img" aria-label="Magnifying Glass">
          🔍
        </span>
      </StatTitle>
      <StatContainer>
        {statsState.currentStats.map((key, index) => (
          <StatBox
            key={statsState.currentStats.indexOf(key)}
            onClick={() => handleBoxClick(statsState.currentStats.indexOf(key))}
            isFlipping={flippingIndex === statsState.currentStats.indexOf(key)}
          >
            <StatBoxFront>
              <StatNumber>
                <AnimatedNumbers
                  key={animatedNumberTrigger[index]}
                  includeComma
                  animateToNumber={stats[key]}
                  locale="en-US"
                  configs={[
                    { mass: 1, tension: 110, friction: 40 },
                    { mass: 2, tension: 130, friction: 40 },
                    { mass: 3, tension: 150, friction: 40 },
                  ]}
                />
              </StatNumber>
              <StatDescription>
                {/* replace _ with space */}
                {key.replace(/_/g, " ")}
              </StatDescription>
            </StatBoxFront>
            <StatBoxBack />
          </StatBox>
        ))}
      </StatContainer>
    </StatWrapper>
  );
};

export default StatDisplays;

const StatWrapper = styled.div``;

const StatTitle = styled.div`
  text-align: center;
  color: #000;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  ${({ theme }) => theme.mediaQueries.tablet`
    font-size: 18px;
  `}
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 24px;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.tablet`
    overflow-x: scroll;
  `}
`;

// Define the flip animation
const flipAnimation = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
`;

const StatBox = styled.div<{ isFlipping: boolean }>`
  display: flex;
  min-height: 240px;
  min-width: 240px;
  height: fit-content;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.globalConstants.color.borderPrimary};
  background: ${({ theme }) =>
    theme.globalConstants.color.backgroundPrimaryLight};

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);

  transform-style: preserve-3d;

  ${({ isFlipping }) =>
    isFlipping &&
    css`
      animation: ${flipAnimation} 1s linear;
      pointer-events: none;
    `}

  ${({ theme }) => theme.mediaQueries.tablet`
    min-height: 180px;
  `}
`;

const StatBoxFront = styled.div`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StatBoxBack = styled.div`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: ${({ theme }) =>
    theme.globalConstants.color.backgroundPrimaryLight};
  transform: rotateX(180deg);
`;

const StatNumber = styled.div`
  color: ${({ theme }) => theme.globalConstants.color.textDark};
  leading-trim: both;
  text-edge: cap;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: 64px;
  font-style: normal;
  font-weight: 900;

  ${({ theme }) => theme.mediaQueries.tablet`
    font-size: 32px;
  `}
`;

const StatDescription = styled.div`
  color: ${({ theme }) => theme.globalConstants.color.textDark};
  leading-trim: both;
  text-edge: cap;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 46px;
  text-transform: uppercase;
  margin-top: 0px;

  ${({ theme }) => theme.mediaQueries.tablet`
    margin: 10px 0;
    font-size: 24px;
    margin-top: 32px;
  `}
`;
