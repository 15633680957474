/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../types.generated";
export type GetFileQueryVariables = Types.Exact<{
  fileId: Types.Scalars["Float"];
}>;

export type GetFileQuery = {
  file: Pick<Types.File, "id" | "name" | "mime_type"> & {
    download: Pick<Types.FileDownload, "uri" | "expiry">;
  };
};

export const GetFileDocument = gql`
  query getFile($fileId: Float!) {
    file(id: $fileId) {
      id
      name
      mime_type
      download {
        uri
        expiry
      }
    }
  }
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetFileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFileQuery,
    GetFileQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export function useGetFileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFileQuery,
    GetFileQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = ApolloReactCommon.QueryResult<
  GetFileQuery,
  GetFileQueryVariables
>;
