/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */
import { gql } from "@apollo/client";

import * as Types from "../../../../api/types.generated";

export type HackerFragmentFragment = Pick<Types.Claim, "id" | "name"> & {
  stage: Pick<Types.Stage, "slug">;
  fields: Array<
    Pick<
      Types.FieldWithAnswer,
      "id" | "field_type" | "slug" | "valid_values" | "updated_at"
    > & {
      answer?: Types.Maybe<
        Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
      >;
    }
  >;
};

export const HackerFragmentFragmentDoc = gql`
  fragment hackerFragment on Claim {
    id
    name
    stage {
      slug
    }
    fields {
      id
      field_type
      slug
      answer {
        id
        value
        updated_at
      }
      valid_values
      updated_at
    }
  }
`;
