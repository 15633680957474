import { ExecutionResult } from "graphql/execution";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useUserContext } from "../UserContext";
import { useGetExtendedProfileLazyQuery } from "../UserContext/graphql/getExtendedProfile.generated";

import {
  UpsertExtendedProfileMutation,
  useUpsertExtendedProfileMutation,
} from "./graphql/upsertExtendedProfile.generated";
import * as Networking from "./types";

export interface NetworkingContextState {
  extendedProfile: Networking.ExtendedProfile | null;
  isLoading: boolean;
  loadError?: string;
  isUpdating: boolean;
  updateError?: string;
  updateExtendedProfile: (
    data: Partial<NetworkingContextState["extendedProfile"]>
  ) => Promise<ExecutionResult<UpsertExtendedProfileMutation>>;
  refetchExtendedProfile: () => void;
}

const DEFAULT_STATE: NetworkingContextState = {
  extendedProfile: null,
  isLoading: false,
  isUpdating: false,
  updateExtendedProfile: () =>
    Promise.reject("No parent NetworkingContext found"),
  refetchExtendedProfile: () =>
    Promise.reject("No parent NetworkingContext found"),
};

const NetworkingContext: React.Context<NetworkingContextState> =
  createContext(DEFAULT_STATE);

export const useNetworkingContext = () => useContext(NetworkingContext);

export const NetworkingContextProvider: React.FC = ({ children }) => {
  const { id } = useUserContext();

  const [
    getExtendedProfileQuery,
    { data: getExtendedProfile, loading, error, refetch },
  ] = useGetExtendedProfileLazyQuery();

  const [
    updateExtendedProfileMutation,
    { loading: updatingExtendedProfile, error: updateExtendedProfileError },
  ] = useUpsertExtendedProfileMutation();

  useEffect(() => {
    if (id) {
      getExtendedProfileQuery({
        variables: {
          user_id: id,
        },
      });
    }
  }, [id, getExtendedProfileQuery]);

  const networkingData =
    getExtendedProfile?.getExtendedProfile?.scannee
      ?.fields_HACKTHENORTH2023_EXTENDED_PROFILES?.answers;

  const extendedProfile: NetworkingContextState["extendedProfile"] = useMemo(
    () =>
      networkingData ? { interests: networkingData.interests?.value } : null,
    [networkingData]
  );

  const updateExtendedProfile = useCallback(
    async (data: Partial<NetworkingContextState["extendedProfile"]>) => {
      if (!id) return Promise.reject("Undefined user id");
      return updateExtendedProfileMutation({
        variables: {
          ...data,
        },
      });
    },
    [id, updateExtendedProfileMutation]
  );

  const refetchExtendedProfile: NetworkingContextState["refetchExtendedProfile"] =
    useCallback(() => {
      if (id && refetch) {
        refetch({
          user_id: id,
        });
      }
    }, [id, refetch]);

  useEffect(() => {
    if (
      getExtendedProfile?.getExtendedProfile != null &&
      getExtendedProfile?.getExtendedProfile?.scannee
        ?.fields_HACKTHENORTH2023_EXTENDED_PROFILES?.answers == null
    ) {
      const defaultExtendedProfile: Partial<
        NetworkingContextState["extendedProfile"]
      > = {
        interests: "",
      };
      updateExtendedProfile(defaultExtendedProfile);
    }
  }, [extendedProfile, getExtendedProfile, updateExtendedProfile]);

  const networkingState: NetworkingContextState = {
    extendedProfile,
    isLoading: loading || updatingExtendedProfile,
    loadError: error?.message,
    isUpdating: updatingExtendedProfile,
    updateError: updateExtendedProfileError?.message,
    updateExtendedProfile,
    refetchExtendedProfile,
  };

  return (
    <NetworkingContext.Provider value={networkingState}>
      {children}
    </NetworkingContext.Provider>
  );
};
