import React, { useMemo } from "react";
import styled, { keyframes } from "styled-components";

import { useWindowSize } from "src/shared/hooks";
import {
  Star1,
  Star2,
  Star3,
  Star4,
  Star5,
  Star6,
  Star7,
  Star8,
  Star9,
  Star10,
  Star11,
} from "src/static/img";
import { deviceBreakpoints, DeviceType } from "src/theme/deviceBreakpoints";

type StarProps = {
  src: string;
  top: number;
  left: number;
};

type ContainerProps = {
  duration: number;
  delay: number;
  top: number;
  left: number;
};

const numStars: Record<DeviceType, number> = {
  extraLarge: 8,
  large: 8,
  medium: 8,
  tablet: 3,
  largeMobile: 3,
  mobile: 3,
  smallMobile: 3,
};

const randomTo = (to: number) => Math.floor(Math.random() * to);
// calculate Euclidian distance
const distance = (from: [number, number], to: [number, number]) =>
  Math.hypot(to[0] - from[0], to[1] - from[1]);

// stars must be > 3% apart
const MIN_STAR_DIST = 3;

const stars = [
  Star1,
  Star2,
  Star3,
  Star4,
  Star5,
  Star6,
  Star7,
  Star8,
  Star9,
  Star10,
  Star11,
];
const generateStars = (numberOfStars: number): StarProps[] => {
  const generated: StarProps[] = [];
  const startStarIdx = randomTo(stars.length);
  for (let i = 0; i < numberOfStars; i++) {
    let x = randomTo(100);
    let y = randomTo(50);

    const isTooClose = (x: number, y: number) =>
      generated.find(
        (pt) => distance([pt.left, pt.top], [x, y]) < MIN_STAR_DIST
      );

    while (isTooClose(x, y)) {
      x = randomTo(100);
      y = randomTo(50);
    }

    generated.push({
      src: stars[(startStarIdx + i) % stars.length],
      top: y,
      left: x,
    });
  }
  return generated;
};

const Stars: React.FC = () => {
  const { windowWidth } = useWindowSize();
  const deviceType: DeviceType = (Object.entries(deviceBreakpoints).find(
    ([, width]) => width <= (windowWidth ?? 0)
  )?.[0] ?? "medium") as DeviceType;

  // re-render stars every time `deviceType` changes
  const starsToRender: StarProps[] = useMemo(
    () => generateStars(numStars[deviceType]),
    [deviceType]
  );

  const duration = Math.random() * 5 + 5;
  const delay = Math.random() * 5 - 4;

  return windowWidth !== undefined ? (
    <div>
      {starsToRender.map((star, i) => {
        return (
          <AnimatedStar
            key={`star_${i}`}
            top={star.top}
            left={star.left}
            duration={duration}
            delay={delay}
          >
            <img src={star.src} alt={`star_${i}`} />
          </AnimatedStar>
        );
      })}
    </div>
  ) : null;
};

const float = keyframes`
  0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-8px);
	}
	100% {
		transform: translatey(0px);
	}
`;

const AnimatedStar = styled.div<ContainerProps>`
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  animation: ${float} ${({ duration }) => duration}s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay}s;
`;

export default React.memo(Stars);
