import { Text } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";

import { PageWrapper } from "src/shared/components";
import { useUserContext } from "src/shared/contexts";

import GraphDisplays from "./graphs";
import StatDisplays from "./statistics";

export const LiveGraphs = () => {
  const { logIn, isAuthenticated } = useUserContext();

  return (
    <PageWrapper>
      {isAuthenticated ? (
        <div>
          <StatDisplays />
          <GraphDisplays />
        </div>
      ) : (
        <Text>
          You&apos;re currently logged out.{" "}
          <Link onClick={logIn}>Click here to log in</Link>.
        </Text>
      )}
    </PageWrapper>
  );
};
