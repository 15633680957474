/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ReactNode } from "react";
import ReactTooltip, { Place } from "react-tooltip";
import styled, { useTheme } from "styled-components";

interface TooltipWrapperProps {
  id?: string;
  color?: string;
  icon: ReactNode;
  content: ReactNode;
  direction?: Place;
  eventDelay?: number;
}

const StyledReactTooltip = styled(ReactTooltip)<{ eventDelay?: number }>`
  border-radius: 10px !important;
  padding: 24px !important;

  opacity: 0 !important;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out !important;
  color: ${({ theme }) => theme.globalConstants.color.white} !important;
  max-width: 285px;

  &.__react_component_tooltip.show {
    opacity: 1 !important;
  }
`;

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  color = "backgroundLight",
  content,
  direction = "right",
  eventDelay,
  icon,
  id = "Tooltip",
}) => {
  const { globalConstants } = useTheme();
  return (
    <>
      <span data-tip data-for={id} style={{ cursor: "pointer" }}>
        {icon}
      </span>
      <StyledReactTooltip
        backgroundColor={globalConstants.color[color]}
        clickable={true}
        delayHide={eventDelay ? eventDelay * 1000 : 0}
        effect="solid"
        eventDelay={eventDelay}
        id={id}
        place={direction}
      >
        {content}
      </StyledReactTooltip>
    </>
  );
};

export default TooltipWrapper;
