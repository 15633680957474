/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */
import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";

import { HackerFragmentFragment } from "./hackerFragment.generated";
import { HackerFragmentFragmentDoc } from "./hackerFragment.generated";

export type GetHackerQueryVariables = Types.Exact<{
  myId: Types.Scalars["Int"];
}>;

export type GetHackerQuery = { claims: Array<HackerFragmentFragment> };

export const GetHackerDocument = gql`
  query GetHacker($myId: Int!) {
    claims(
      take: 1
      where: {
        pipeline: { is: { slug: { equals: "hackthenorth2023-applications" } } }
        user_id: { equals: $myId }
      }
    ) {
      ...hackerFragment
    }
  }
  ${HackerFragmentFragmentDoc}
`;

/**
 * __useGetHackerQuery__
 *
 * To run a query within a React component, call `useGetHackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHackerQuery({
 *   variables: {
 *      myId: // value for 'myId'
 *   },
 * });
 */
export function useGetHackerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHackerQuery,
    GetHackerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetHackerQuery, GetHackerQueryVariables>(
    GetHackerDocument,
    baseOptions
  );
}
export function useGetHackerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHackerQuery,
    GetHackerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetHackerQuery, GetHackerQueryVariables>(
    GetHackerDocument,
    baseOptions
  );
}
export type GetHackerQueryHookResult = ReturnType<typeof useGetHackerQuery>;
export type GetHackerLazyQueryHookResult = ReturnType<
  typeof useGetHackerLazyQuery
>;
export type GetHackerQueryResult = ApolloReactCommon.QueryResult<
  GetHackerQuery,
  GetHackerQueryVariables
>;
