import {
  CalendarIcon,
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  HomeIcon,
  InboxArrowDownIcon,
  KeyIcon,
  ShareIcon,
  SparklesIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon as CalendarIconSolid,
  CheckCircleIcon as CheckCircleIconSolid,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
  HomeIcon as HomeIconSolid,
  InboxArrowDownIcon as InboxArrowDownIconSolid,
  KeyIcon as KeyIconSolid,
  ShareIcon as ShareIconSolid,
  SparklesIcon as SparklesIconSolid,
  UserCircleIcon as UserCircleIconSolid,
  UserGroupIcon as UserGroupIconSolid,
} from "@heroicons/react/24/solid";
import Cookies from "js-cookie";
import { CSSProperties } from "styled-components";

import { Route } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts/PermissionsContext/types";

export type TSidebarSection = {
  title?: string;
  content: TSidebarItem[];
};

export type TSidebarItem = {
  name: string;
  href: string;
  onClick?: () => void;
  icon: React.FC<{ style?: CSSProperties }>;
  activeIcon: React.FC<{ style?: CSSProperties }>;
  permissions?: Permission[];
  antiPermissions?: Permission[];
};

export const GENERAL_SECTION: TSidebarSection = {
  content: [
    {
      name: "Home",
      href: Route.HOME,
      icon: HomeIcon,
      activeIcon: HomeIconSolid,
    },
  ],
};

export const SECTIONS: TSidebarSection[] = [GENERAL_SECTION];
