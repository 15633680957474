import { DateTime, DurationInput } from "luxon";

import { THackerAPIDatetime } from "./hackerapi";

const pad = (n: number) => {
  return n < 10 ? "0" + n : n;
};

export const addThirtyMinutes = (time: Date | null | undefined) => {
  if (time) {
    time = new Date(time);
    time.setMinutes(time.getMinutes() + 30);
    return time;
  }
  return null;
};

export const addTwoHours = (time: Date | null | undefined) => {
  if (time) {
    time = new Date(time);
    time.setHours(time.getHours() + 2);
    return time;
  }
  return null;
};

export type TFormatDateTime = (
  start: THackerAPIDatetime,
  duration: DurationInput
) => string;

// Returns formatted time range to EST
// Defaults to a range of 1 hour
export const formatTimeRangeV2: TFormatDateTime = (start, duration) => {
  const startDateTime = DateTime.fromISO(start).setZone("America/Toronto");
  const endDateTime = startDateTime.plus(duration);

  return `${startDateTime.toFormat("MMM dd, h:mm a")} - ${endDateTime.toFormat(
    "MMM dd, h:mm a"
  )}`;
};

// Returns formatted time in the browser's local timezone
// Single time, not a range
export const formatTime = (time: Date, mentorship?: boolean) => {
  time = new Date(time);
  const day = time.toLocaleDateString("en-US", {
    weekday: mentorship ? undefined : "long",
    month: mentorship ? "short" : "long",
    day: "numeric",
  });
  const hours = time.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hour = hours % 12 || 12; // 0 (12am) should be displayed as 12
  const minute = time.getMinutes();
  const startTime = `${hour}:${pad(minute)}`;
  const at = mentorship ? "at" : "@";

  return `${day} ${at} ${startTime} ${ampm}`;
};

export const formatTimeV2 = (time: THackerAPIDatetime) => {
  const dateTime = DateTime.fromISO(time).setZone("America/Toronto");

  const day = `${dateTime.weekdayLong}, ${dateTime.monthLong} ${dateTime.day}`;

  const isStartAM = dateTime.hour < 12;
  const hour =
    dateTime.hour > 12
      ? dateTime.hour - 12
      : dateTime.hour === 0
      ? 12
      : dateTime.hour;
  const minute = dateTime.minute < 10 ? `0${dateTime.minute}` : dateTime.minute;
  const startTime = `${hour}:${minute}`;
  return `${day} @ ${startTime} ${isStartAM ? "AM" : "PM"}`;
};

// Returns a relative time
// now, 1 second ago, 15 seconds ago
// 1 minute ago, 2 minutes ago, 30 minutes ago
// 1 hour ago, 2:15 PM, 1:15 PM, 11:13 AM
// December 14, December 11
export const formatRelativeTime = (time: Date) => {
  time = new Date(time);
  const delta = +Date.now() - +time;
  const day = time.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
  if (delta >= 24 * 60 * 60 * 1000) {
    // Greater than a day ago
    return day;
  } else if (delta > 1.5 * 60 * 60 * 1000) {
    // Greater than 1:30 hours ago
    const hours = time.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hour = hours > 12 ? hours - 12 : hours;
    const minute = time.getMinutes();
    return `${hour}:${pad(minute)} ${ampm}`;
  } else if (delta > 0.75 * 60 * 60 * 1000) {
    // Greater than 45 minutes ago
    return "1 hour ago";
  } else if (delta >= 60 * 1000) {
    // Greater than or equal to 1 minute
    const minuteDelta = Math.floor(delta / (60 * 1000));
    return `${minuteDelta} minute${minuteDelta === 1 ? "" : "s"} ago`;
  } else if (delta >= 1000) {
    // Greater than or equal to 1 second
    const secondDelta = Math.floor(delta / 1000);
    return `${secondDelta} second${secondDelta === 1 ? "" : "s"} ago`;
  } else {
    return "now";
  }
};

export const formatRelativeTimeV2 = (time: Date) => {
  const d = DateTime.fromJSDate(time);
  return d.toRelative();
};

export const getTimeBetween = (startDate: Date, endDate: Date) => {
  let difference = endDate.getTime() - startDate.getTime();

  difference = difference / 1000;
  const seconds = Math.floor(difference % 60);
  difference = difference / 60;
  const minutes = Math.floor(difference % 60);
  difference = difference / 60;
  const hours = Math.floor(difference % 24);
  const days = Math.floor(difference / 24);

  return [days, hours, minutes, seconds];
};

export const formatFlipTime = (time: number) => {
  return Math.max(time, 0).toLocaleString("en-US", { minimumIntegerDigits: 2 });
};
