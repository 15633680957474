import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.regular};
  background: rgba(0, 0, 0, 0.1);
  display: inline-block;
  align-self: flex-start;
`;

const Img = styled.img`
  opacity: 0;
  background: white;
  height: 5em;
  width: 5em;
  object-fit: contain;
  transition: opacity 500ms;
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
`;

export default function Image(props: React.ComponentPropsWithoutRef<"img">) {
  return (
    <Container>
      <Img {...props} />
    </Container>
  );
}
