import React, { useMemo } from "react";
import { styled } from "twin.macro";

import { CustomBoat, FlipCardClock, Stars } from "src/shared/components";
import {
  PHASE_1_RELEASE_DATE,
  HARDWARE_TOOL_RELEASE_DATE,
  PHASE_2_RELEASE_DATE,
  PHASE_3_RELEASE_DATE,
} from "src/shared/constants/deadlines";
import { useHackerContext } from "src/shared/contexts";
import { TWText } from "src/shared/styles";

type LockedPageProps = {
  phase1: boolean;
  phase2: boolean;
  phase3: boolean;
  hardware: boolean;
};

export const LockedPage: React.FC<LockedPageProps> = ({
  phase1,
  phase2,
  phase3,
  hardware,
}) => {
  const { claimData } = useHackerContext();

  const boat_custom_color = useMemo(
    () => claimData.boat_custom_color,
    [claimData]
  );

  const boat_custom_expression = useMemo(
    () => claimData.boat_custom_expression,
    [claimData]
  );

  const boat_custom_accessory = useMemo(
    () => claimData.boat_custom_accessory,
    [claimData]
  );

  return (
    <Container tw="w-full min-h-full flex justify-center align-items[center]">
      <Stars />
      <div tw="flex flex-col justify-center align-items[center]">
        <CustomBoat
          color={(boat_custom_color as string) ?? "pink"}
          expression={(boat_custom_expression as string) ?? "smile"}
          accessory={(boat_custom_accessory as string) ?? "flag"}
        />
        <div tw="mt-32" />
        {phase1 && <span css={TWText.h3}>Coming soon on September 7th</span>}
        {hardware && <span css={TWText.h3}>Coming soon on September 10th</span>}
        {phase2 && <span css={TWText.h3}>Coming soon on September 12th</span>}
        {phase3 && <span css={TWText.h3}>Coming soon on September 16th</span>}
        <div tw="mt-24" />
        <FlipCardClock
          date={
            phase1
              ? PHASE_1_RELEASE_DATE
              : phase2
              ? PHASE_2_RELEASE_DATE
              : phase3
              ? PHASE_3_RELEASE_DATE
              : hardware
              ? HARDWARE_TOOL_RELEASE_DATE
              : undefined
          }
        />
      </div>
    </Container>
  );
};

const Container = styled.main`
  background: ${({ theme }) =>
    theme.globalConstants.color.backgroundDangerLight};

  padding: 70px;

  ${({ theme }) => theme.mediaQueries.medium`
    padding: 45px;
  `}
  ${({ theme }) => theme.mediaQueries.tablet`
    padding: 40px;
  `}
  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.smallMobile`
    padding: 20px;
  `}
`;
