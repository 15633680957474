import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

export const dropdownInputStyles: ThemeDefinition["dropdownInput"] = {
  sharedStyles: css`
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    background-color: ${({ theme }) =>
      theme.globalConstants.color.backgroundLight};
    border-radius: 16px;
    color: ${({ theme }) => theme.globalConstants.color.textDark};
    width: 100%;
    outline: none;

    .select__menu {
      box-shadow: none;
      border: 1px solid
        ${({ theme }) => theme.globalConstants.color.brandPrimary};
      border-radius: 16px;
    }

    .select__control {
      border: 1px solid
        ${({ theme }) => theme.globalConstants.color.brandPrimary};
      border-radius: 16px;
      &:hover {
        border-color: ${({ theme }) => theme.globalConstants.color.textDark};
      }
      outline: none;
      box-shadow: none;
    }

    .select__control--is-focused {
      border-radius: 16px;
      border-color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
    }

    .select__option--is-focused {
      border-radius: 16px;
    }

    .select__option {
      &:hover {
        cursor: pointer;
        border-radius: 16px;
        background-color: ${({ theme }) =>
          theme.globalConstants.color.brandSecondary};
        color: ${({ theme }) => theme.globalConstants.color.textDark};
      }
    }

    .select__value-container {
      border-radius: 16px;
      outline: none;
    }

    .select__single-value {
      border-radius: 16px;
      outline: none;
    }
  `,
};
