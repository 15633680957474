import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { sharedInputStyles } from "./textInput";

export const textAreaInputStyles: ThemeDefinition["textAreaInput"] = {
  sharedStyles: css`
    ${sharedInputStyles}
    padding: 18px 24px;
    min-height: 144px;
    resize: vertical;
  `,
  modifiers: {
    error: css`
      border-color: ${({ theme }) =>
        theme.globalConstants.color.backgroundDanger};
    `,
  },
};
