import { Text } from "@hackthenorth/north";
import React, { useEffect, useState } from "react";
import { default as D3WordCloud } from "react-d3-cloud";
import styled, { useTheme } from "styled-components";

import { IS_PRODUCTION } from "src/shared/utils/env";

import { useGetSignageAnswerFrequencyQuery } from "..//graphql/getSignageAnswerFrequency.generated";

const MAX_FONT_SIZE = 24;
const MIN_FONT_SIZE = 12;

export interface WordData {
  text: string;
  value: number;
}

const WordCloud = () => {
  const theme = useTheme();

  const { data, loading, error, refetch } = useGetSignageAnswerFrequencyQuery({
    variables: {
      question_id: IS_PRODUCTION ? 40 : 54,
    },
  });

  const [words, setWords] = useState<any[]>([]);

  useEffect(() => {
    if (data && data.getSignageAnswerFrequency) {
      const dictionary = JSON.parse(data.getSignageAnswerFrequency) as Record<
        string,
        number
      >;

      // Convert the dictionary to an array of WordData objects
      const formattedWords: WordData[] = Object.entries(dictionary).map(
        ([text, value]) => ({ text, value })
      );

      setWords(formattedWords);
    }
  }, [data]);

  useEffect(() => {
    // Set up a refetch interval
    const intervalId = setInterval(() => {
      refetch();
    }, 15000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [refetch]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>There was an error, try again!</Text>;
  }

  const words_copy = [];
  // push all the words in the state to the wordss array
  // im not sure why this happens but the component doesnt like a state array.
  for (let i = 0; i < words.length; i++) {
    words_copy.push(words[i]);
  }

  const highest = Math.max(...words_copy.map((w) => w.value));
  const lowest = Math.min(...words_copy.map((w) => w.value));

  // Font size scaling function
  const fontSize = (d: WordData) => {
    // Avoid division by zero
    const range = highest - lowest;
    if (range === 0) return MAX_FONT_SIZE; // Return the minimum size if all values are the same

    // Scale value to the range [8, 25]
    return (
      ((d.value - lowest) / range) * (MAX_FONT_SIZE - MIN_FONT_SIZE) +
      MIN_FONT_SIZE
    );
  };

  return (
    <>
      <Text mods="heading h1 bold">
        In one word, what does “dream big” mean to you?
      </Text>
      <WordCloudWrapper>
        {/* <ReactWordcloud words={words} options={options} callbacks={callbacks} /> */}

        <D3WordCloud
          data={words_copy}
          minSize={8}
          maxSize={25}
          height={250}
          font={theme.globalConstants.fontFamily.body}
          fontSize={fontSize}
          spiral="rectangular"
          rotate={() => Math.random() * 40 - 20} // Random rotation
          padding={5}
          random={Math.random}
          // fill={(d, i) => theme.globalConstants.color.primary} // Customize color as needed
          // onWordClick={(event, d) => {
          //   console.log(`Clicked on: ${d.text}`);
          // }}
          // onWordMouseOver={(event, d) => {
          //   console.log(`Mouse over: ${d.text}`);
          // }}
          // onWordMouseOut={(event, d) => {
          //   console.log(`Mouse out: ${d.text}`);
          // }}
        />
      </WordCloudWrapper>
    </>
  );
};

export default WordCloud;

const WordCloudWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 300px;
`;
