import { ExecutionResult } from "graphql";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useNavigate, useLocation } from "react-router";

import {
  useUpdateClaimMutation,
  UpdateClaimMutation,
} from "src/api/claims/updateClaim.generated";
import { Route } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";
import {
  getFieldAnswer,
  parseState,
  convertToFileAnswers,
} from "src/shared/utils/hackerapi";

import { DEFAULT_DATA } from "./defaults";
import {
  GetHackerQuery,
  useGetHackerLazyQuery,
} from "./graphql/getHacker.generated";
import {
  UpdateHackerStageMutation,
  useUpdateHackerStageMutation,
} from "./graphql/updateHackerStage.generated";
import { HackerStage, TAnswers, Field } from "./types";

const FIELDS = Object.values(Field);

// TODO: Improve this
const parseClaim = (data: GetHackerQuery["claims"][0] | undefined) =>
  FIELDS.reduce((obj, field) => {
    obj[field] = getFieldAnswer(data?.fields, field) ?? DEFAULT_DATA[field];
    return obj;
  }, {}) as TAnswers;

export type THackerContextState = {
  isFetchingData: boolean;
  isUpdatingData: boolean;
  isUpdatingStage: boolean;
  isLoading: boolean;
  isReadOnly: boolean;
  error: string | undefined;
  stage: HackerStage | undefined;
  claimData: TAnswers;
  updateStage: (
    newStage: HackerStage
  ) => Promise<ExecutionResult<UpdateHackerStageMutation>>;
  updateResponses: (
    updatedData?: Partial<TAnswers>
  ) => Promise<ExecutionResult<UpdateClaimMutation>>;
  navigateToRSVP: () => void;
  navigateNext: () => void;
  navigateBack: () => void;
  navigateToTravelForm: () => void;
  refetch: ReturnType<typeof useGetHackerLazyQuery>["1"]["refetch"];
  // reimbursementAmount: number;
};

const DEFAULT_STATE: THackerContextState = {
  isFetchingData: false,
  isUpdatingData: false,
  isUpdatingStage: false,
  isLoading: false,
  isReadOnly: true,
  error: undefined,
  stage: undefined,
  claimData: DEFAULT_DATA,
  updateStage: () => Promise.reject("No parent HackerContextProvider found"),
  updateResponses: () =>
    Promise.reject("No parent HackerContextProvider found"),
  navigateToRSVP: () => {},
  navigateNext: () => {},
  navigateBack: () => {},
  navigateToTravelForm: () => {},
  refetch: () => Promise.reject("No parent HackerContextProvider found"),
};

const HackerContext: React.Context<THackerContextState> =
  createContext(DEFAULT_STATE);

export const useHackerContext = () => useContext(HackerContext);

const removeFields = (state: TAnswers) => {
  Object.entries(state).forEach(([key]) => {
    if (
      key.startsWith("team_formation") ||
      key.includes("discord_id") ||
      key.includes("interests")
      // || key.includes("discord_tag")
    ) {
      delete state[key];
    }
  });
};

const convertFieldTypes = (state: TAnswers) => {
  if (state[Field.TRAVEL_RECEIPT]) {
    state[Field.TRAVEL_RECEIPT] = convertToFileAnswers(
      state[Field.TRAVEL_RECEIPT]
    );
  }

  if (state[Field.RESUME]) {
    state[Field.RESUME] = convertToFileAnswers(state[Field.RESUME]);
  }

  if (state[Field.WAIVER_CONSENT]) {
    state[Field.WAIVER_CONSENT] = convertToFileAnswers(
      state[Field.WAIVER_CONSENT]
    );
  }
};

export const HackerContextProvider: React.FC = ({ children }) => {
  const { id } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [
    getHackerQuery,
    { loading: getLoading, data: getData, error: getError, refetch },
  ] = useGetHackerLazyQuery();

  // const [getTravelReimbursementAmountQuery, { data: getReimbursementData }] =
  //   useTravelReimbursementAmountLazyQuery();

  const [
    updateHacker,
    {
      loading: updateHackerLoading,
      data: updateHackerData,
      error: updateHackerError,
    },
  ] = useUpdateClaimMutation();

  const [
    updateHackerStage,
    {
      loading: updateStageLoading,
      data: updateStageData,
      error: updateStageError,
    },
  ] = useUpdateHackerStageMutation();

  // fetch responses from hapi (if user id is defined)
  useEffect(() => {
    if (id) getHackerQuery({ variables: { myId: id } });
  }, [id, getHackerQuery]);

  const claim = getData?.claims?.[0];

  const stage = (updateStageData?.updateClaim?.stage.slug ??
    claim?.stage.slug) as THackerContextState["stage"];

  const claimData = parseClaim(claim ?? updateHackerData?.updateClaim);

  const updateStage = useCallback(
    async (newStage: HackerStage) => {
      const id = claim?.id;

      if (!id) return Promise.reject("Undefined claim id");

      return await updateHackerStage({
        variables: {
          id,
          newStage,
        },
      });
    },
    [claim, updateHackerStage]
  );

  const updateResponses = useCallback(
    async (updatedData: Partial<TAnswers> = {}) => {
      const id = claim?.id;

      if (!id) return Promise.reject("Undefined claim id");

      const state = { ...claimData, ...updatedData };

      // make sure team formation fields, discord_id, and interests are not passed to update claim
      removeFields(state);

      convertFieldTypes(state);

      return updateHacker({
        variables: {
          updatedData: {
            id,
            answers: parseState(state),
          },
        },
      });
    },
    [claim, updateHacker, claimData]
  );

  const navigateToRSVP = () => navigate(Route.HACKER_RSVP);

  const navigateNext = () => {
    switch (location.pathname) {
      case Route.HOME:
        navigate(Route.HACKER_PERSONAL);
        break;
      case Route.HACKER_RSVP:
        navigate(Route.HACKER_PERSONAL);
        break;
      case Route.HACKER_PERSONAL:
        navigate(Route.HACKER_TRAVEL);
        break;
      case Route.HACKER_TRAVEL:
        navigate(Route.HACKER_EVENT);
        break;
      case Route.HACKER_TRAVEL_DETAILS:
        navigateToRSVP();
        window.location.reload();
        break;
      case Route.HACKER_EVENT:
        navigate(Route.HACKER_CAREER);
        break;
      case Route.HACKER_CAREER:
        navigate(Route.HACKER_CONFIRMATION);
        break;
      case Route.HACKER_CONFIRMATION:
      default:
        navigateToRSVP();
    }
    window.scrollTo(0, 0);
  };

  const navigateBack = () => {
    switch (location.pathname) {
      case Route.HACKER_TRAVEL:
        navigate(Route.HACKER_PERSONAL);
        break;
      case Route.HACKER_EVENT:
        navigate(Route.HACKER_TRAVEL);
        break;
      case Route.HACKER_CAREER:
        navigate(Route.HACKER_EVENT);
        break;
      case Route.HACKER_CONFIRMATION:
        navigate(Route.HACKER_CAREER);
        break;
      default:
        navigateToRSVP();
    }
    window.scrollTo(0, 0);
  };

  const navigateToTravelForm = () => {
    navigate(Route.HACKER_TRAVEL_DETAILS);
    window.scrollTo(0, 0);
  };

  const isFetchingData = getLoading || !claim; // There can be a state where getLoading = false and claim = undefined, so we use OR instead of AND
  const isUpdatingData = updateHackerLoading && !updateHackerData;
  const isUpdatingStage = updateStageLoading && !updateStageData;

  /**
   * Build state
   */
  const hackerState: THackerContextState = {
    isFetchingData: isFetchingData,
    isUpdatingData: isUpdatingData,
    isUpdatingStage: isUpdatingStage,
    isLoading: isFetchingData || isUpdatingData || isUpdatingStage,
    isReadOnly:
      !!stage &&
      [HackerStage.CHECKED_IN, HackerStage.WITHDRAWN].includes(stage),
    error:
      getError?.message ??
      updateHackerError?.message ??
      updateStageError?.message ??
      undefined,
    stage,
    claimData,
    updateStage,
    updateResponses,
    navigateToRSVP,
    navigateNext,
    navigateBack,
    navigateToTravelForm,
    refetch,
  };

  return (
    <HackerContext.Provider value={hackerState}>
      {children}
    </HackerContext.Provider>
  );
};
