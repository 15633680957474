/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetClaimedRequestsQueryVariables = Types.Exact<{
  mentorId: Types.Scalars["String"];
  stages: Array<Types.Scalars["String"]> | Types.Scalars["String"];
}>;

export type GetClaimedRequestsQuery = {
  claims: Array<
    Pick<Types.Claim, "id" | "name" | "created_at"> & {
      stage: Pick<Types.Stage, "slug">;
      fields: Array<
        Pick<
          Types.FieldWithAnswer,
          "id" | "field_type" | "slug" | "valid_values" | "updated_at"
        > & {
          answer?: Types.Maybe<
            Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
          >;
        }
      >;
    }
  >;
};

export const GetClaimedRequestsDocument = gql`
  query GetClaimedRequests($mentorId: String!, $stages: [String!]!) {
    claims(
      orderBy: { updated_at: desc }
      where: {
        stage: { is: { slug: { in: $stages } } }
        pipeline: {
          is: { slug: { equals: "hackthenorth2023-mentorship-requests" } }
        }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "mentor_id" } } } }
              { value: { equals: $mentorId } }
            ]
          }
        }
      }
    ) {
      id
      name
      stage {
        slug
      }
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
      created_at
    }
  }
`;

/**
 * __useGetClaimedRequestsQuery__
 *
 * To run a query within a React component, call `useGetClaimedRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimedRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimedRequestsQuery({
 *   variables: {
 *      mentorId: // value for 'mentorId'
 *      stages: // value for 'stages'
 *   },
 * });
 */
export function useGetClaimedRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >(GetClaimedRequestsDocument, baseOptions);
}
export function useGetClaimedRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >(GetClaimedRequestsDocument, baseOptions);
}
export type GetClaimedRequestsQueryHookResult = ReturnType<
  typeof useGetClaimedRequestsQuery
>;
export type GetClaimedRequestsLazyQueryHookResult = ReturnType<
  typeof useGetClaimedRequestsLazyQuery
>;
export type GetClaimedRequestsQueryResult = ApolloReactCommon.QueryResult<
  GetClaimedRequestsQuery,
  GetClaimedRequestsQueryVariables
>;
