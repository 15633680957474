/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { Place } from "react-tooltip";
import styled from "styled-components";

import TooltipWrapper from "./Wrapper";

interface IndicatorProps extends React.ComponentPropsWithoutRef<"div"> {
  /**
   * Pass in the name of a globalConstant fontSize value. Defaults to `body` size.
   */
  size?: string;
  indicatorColor?: string;
}

interface TooltipProps extends IndicatorProps {
  id?: string;
  wrapperOptions?: {
    color?: string;
    direction?: Place;
    eventDelay?: number;
  };
}

const Indicator = styled.div<IndicatorProps>`
  position: relative;
  width: ${({ theme, size }) => `${theme.globalConstants.fontSize[size!]}px`};
  height: ${({ theme, size }) => `${theme.globalConstants.fontSize[size!]}px`};

  font-size: ${({ theme, size }) =>
    `${Math.round(theme.globalConstants.fontSize[size!] * 0.7)}px`};
  line-height: ${({ theme, size }) =>
    `${theme.globalConstants.fontSize[size!]}px`};

  display: inline-block;
  text-align: center;
  background-color: ${({ color, theme }) =>
    theme.globalConstants.color[color!] || color};
  border-radius: 50%;
  cursor: pointer;

  &::before {
    content: "?";
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-weight: bold;
    color: ${({ theme }) => theme.globalConstants.color.white};
  }
`;

const Tooltip: React.FC<TooltipProps> = ({
  children,
  id,
  indicatorColor = "textDark",
  size = "body",
  wrapperOptions,
  ...rest
}) => (
  <TooltipWrapper
    id={id}
    icon={<Indicator color={indicatorColor} size={size} {...rest} />}
    content={children}
    {...wrapperOptions}
  />
);

export default Tooltip;
