export enum Permission {
  HACKER = "hacker",
  VOLUNTEER = "volunteer",
  VIEW_FULL_SCHEDULE = "view-full-schedule",

  SPONSOR = "sponsor",
  SPONSOR_RECRUITMENT_ACCESS = "sponsor-recruitment-access",

  HACKER_RSVP = "hacker-rsvp",
  HACKER_RSVP_NOT_RESTRICTED = "hacker-rsvp-not-restricted",

  HACKER_EVENT_ACCESS = "hacker-event-access",

  MENTOR = "mentor",

  WORKSHOP_LEAD = "workshop_lead",

  STAGING = "staging",

  ORGANIZER = "organizer",
}
