import styled from "styled-components";
import tw from "twin.macro";

import { mediaQueries } from "src/shared/utils/responsive";

export const TWText = {
  // Unfixed : https://github.com/tailwindlabs/tailwindcss/issues/2920
  title: tw`font-heading font-weight[900] text-text-primary text-68 leading-normal tb:(text-48) lm:(text-44!) mb:(text-36!)`,
  h1: tw`font-heading font-weight[900] text-text-primary text-44 leading-normal tb:(text-36) lm:(text-32!)`,
  h2: tw`font-heading font-weight[900] text-text-primary text-36 leading-normal tb:(text-32) lm:(text-28! leading-relaxed)`,
  h3: tw`font-heading font-weight[900] text-text-primary text-28 leading-normal tb:(text-24 leading-relaxed) lm:(text-18!) mb:(text-16!)`,
  h4: tw`font-heading font-weight[900] text-text-primary text-24 leading-normal tb:(text-18 leading-relaxed) lm:(text-16! leading-loose)`,
  bodyLarge: tw`font-body font-weight[bold] text-text-primary text-18 leading-loose tb:(text-16 leading-extra)`,
  body: tw`font-body font-weight[normal] text-text-primary text-16 leading-loose tb:(text-14 leading-extra)`,
  bodyBold: tw`font-body font-weight[bold] text-text-primary text-16 leading-loose tb:(text-14 leading-extra)`,
  cta: tw`font-body font-weight[500] text-text-primary text-16 leading-loose tb:(text-14 leading-extra)`,
  link: tw`text-text-primary`,
  label: tw`font-body font-weight[normal] text-text-primary text-14 leading-extra tb:(text-12)`,
};

export const theme = {
  colors: {
    background: {
      black: "#141425",
      yellow: "#F7CE58",
      turquoise: "#19FBFF",
      purple: "#896BFF",
      blue: "#1FA6FF",
      orange: "#F09343",
    },
    text: {
      black: "#1F2937",
      grey: "#6B7280",
    },
  },
};

export const Heading2 = styled.h2`
  font-family: Castledown, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  ${mediaQueries.largeMobile} {
    font-size: 24px;
  }
`;

export const Heading3 = styled.h3`
  font-family: Castledown, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const SecondaryText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

export const Body = styled.p`
  font-size: 16px;
  font-style: normal;
  line-height: normal;
`;

export const BodyBold = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
`;
