import { Text, TextAreaInput, TextAreaInputProps } from "@hackthenorth/north";
import React, { useCallback, ChangeEvent } from "react";
import styled from "styled-components";

export interface CharacterLimitTextAreaProps
  extends Omit<TextAreaInputProps, "theme"> {
  charLimit: number;
}

const WordCounter = styled(Text)`
  left: 26px;
  bottom: 24px;
`;

const Container = styled.div`
  position: relative;
`;

const CharacterLimitTextArea: React.FC<CharacterLimitTextAreaProps> = ({
  charLimit,
  onChange,
  value,
  ...rest
}) => {
  const charCount = (value as string)?.length;

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const charList = e.target.value;
      if (charList.length > charLimit) {
        e.target.value = `${charList.slice(0, charLimit)}`;
        return;
      }
      if (onChange) onChange(e);
    },
    [onChange, charLimit]
  );

  return (
    <Container>
      <TextAreaInput value={value} {...rest} onChange={internalOnChange} />
      <WordCounter mods="lightGrey">
        {charCount}/{charLimit} characters
      </WordCounter>
    </Container>
  );
};

export default CharacterLimitTextArea;
