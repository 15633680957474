/**
 * TODO: MAKE THIS INTO AN IndividualsContext or something so it can fetch information about any role
 */
/* eslint-disable @typescript-eslint/camelcase */
import { ApolloQueryResult } from "@apollo/client";
import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { BaseAttendeeContextState } from "src/shared/contexts/types";
import { useUserContext } from "src/shared/contexts/UserContext";

import {
  useGetCompanyNameLazyQuery,
  GetCompanyNameQuery,
} from "./graphql/getCompany.generated";
import {
  useGetSponsorCompanyLazyQuery,
  GetSponsorCompanyQuery,
} from "./graphql/getSponsorCompany.generated";
import {
  useGetSponsorIndividualLazyQuery,
  GetSponsorIndividualQuery,
} from "./graphql/getSponsorIndividual.generated";

interface SponsorContextState extends BaseAttendeeContextState {
  // TODO: Fix typing
  individual?: GetSponsorIndividualQuery["sponsorIndividual"];
  company?: GetSponsorCompanyQuery["sponsorCompany"] & {
    name: GetCompanyNameQuery["company"]["name"];
  };

  refetchSponsorCompany: (() => Promise<ApolloQueryResult<any>>) | undefined;
}

const DEFAULT_STATE: SponsorContextState = {
  refetchSponsorCompany: () => Promise.reject(),

  loading: false,
  error: null,
};

const SponsorContext: React.Context<SponsorContextState> =
  createContext(DEFAULT_STATE);

export const useSponsorContext = () => useContext(SponsorContext);

export const SponsorContextProvider: React.FC = ({ children }) => {
  const { info: userInfo, isOrganizer } = useUserContext();

  const [
    getSponsorIndividual,
    {
      data: getIndividualData,
      loading: fetchingIndividualData,
      error: getIndividualError,
    },
  ] = useGetSponsorIndividualLazyQuery();

  const [
    getSponsorCompany,
    {
      data: getSponsorData,
      loading: fetchingSponsorData,
      error: getSponsorError,
      refetch: refetchSponsorCompany,
    },
  ] = useGetSponsorCompanyLazyQuery();

  const [
    getCompanyName,
    {
      data: getCompanyData,
      loading: fetchingCompanyData,
      error: getCompanyError,
    },
  ] = useGetCompanyNameLazyQuery();

  const individual = getIndividualData?.sponsorIndividual;
  const sponsor = getSponsorData?.sponsorCompany;
  const companyData = getCompanyData?.company;
  // temporarily fix infinite loading bug bc of corrupted data
  // const loading =
  //   individual === undefined ||
  //   sponsor === undefined ||
  //   companyData === undefined;
  const loading =
    fetchingIndividualData || fetchingSponsorData || fetchingCompanyData;
  const company =
    sponsor !== undefined && companyData !== undefined
      ? { ...sponsor, name: companyData.name }
      : undefined;

  /**
   * Fetch data only when their dependant variables are defined
   */

  useEffect(() => {
    if (userInfo?.email) {
      console.debug("loading set to true");
      getSponsorIndividual({
        variables: { email: userInfo?.email },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isOrganizer]);

  useEffect(() => {
    if (individual?.sponsor_company_slug) {
      getSponsorCompany({
        variables: { slug: individual.sponsor_company_slug },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individual]);

  useEffect(() => {
    if (sponsor?.company_id) {
      getCompanyName({ variables: { companyId: sponsor.company_id } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsor]);

  /**
   * Build state
   */
  const state: SponsorContextState = {
    individual,
    company,

    refetchSponsorCompany,

    loading,
    error:
      getIndividualError?.message ??
      getSponsorError?.message ??
      getCompanyError?.message ??
      null,
  };

  return (
    <SponsorContext.Provider value={state}>{children}</SponsorContext.Provider>
  );
};
