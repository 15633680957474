import React, { useState, useEffect } from "react";
import { styled, theme } from "twin.macro";

import { Icon } from "src/shared/components";

export type ItemTypeProps = {
  id: string;
  node: React.ReactNode;
};

type TDropdownProps = {
  label: React.ReactNode;
  items: ItemTypeProps[];
  width?: string;
  anchor?: "left" | "right";
  showDividers?: boolean;
  onClick?: (selectedId: string) => void;
};

const Dropdown: React.FC<TDropdownProps> = ({
  label,
  width = "100%",
  anchor = "left",
  items,
  showDividers = false,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // close the dropdown when clicked outside of it
  useEffect(() => {
    const closeDropdown = () => setIsOpen(false);
    window.addEventListener("click", closeDropdown);

    return () => window.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <DecoratedLabel onClick={() => setIsOpen(!isOpen)}>
        {label}
        <Icon
          name="chevron"
          fill={theme("colors.brand.primary")}
          style={{ marginLeft: 8 }}
        />
      </DecoratedLabel>
      <ItemsWrapper isOpen={isOpen} anchor={anchor} width={width}>
        {isOpen &&
          items.map((item) => (
            <Item
              key={item.id}
              showDividers={showDividers}
              onClick={() => onClick && onClick(item.id)}
            >
              {item.node}
            </Item>
          ))}
      </ItemsWrapper>
    </Wrapper>
  );
};

const DecoratedLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  padding: 12px;
`;

const Item = styled.div<{ showDividers: boolean }>`
  display: flex;
  align-items: center;

  ${({ showDividers, theme }) =>
    showDividers &&
    `&:not(:first-of-type) {
      border-top: 1px solid ${theme.globalConstants.color.textLight};
    }`}
`;

const ItemsWrapper = styled.div<{
  isOpen: boolean;
  anchor: "left" | "right";
  width: string;
}>`
  position: absolute;
  z-index: 10;
  margin-top: 54px;
  border-radius: 16px;
  padding: 8px 0;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  right: ${({ anchor }) => (anchor === "right" ? 0 : undefined)};
  width: ${({ width }) => width};
  background: ${({ theme }) => theme.globalConstants.color.white};
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textLight};
  box-shadow: 0px 10px 12px -1px rgba(0, 0, 0, 0.15);
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 16px;
  background: ${({ theme }) => theme.globalConstants.color.white};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textLight};
  color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
  cursor: pointer;
  user-select: none;
`;

export default Dropdown;
