import { AcceptedFileType, ImageFileType, VideoFileType } from "./types";

export const IMAGE_FILE_TYPES: ImageFileType[] = [
  "svg",
  "png",
  "jpg",
  "jpeg",
  "pdf",
];
export const VIDEO_FILE_TYPES: VideoFileType[] = ["flac", "mp4", "ogg", "webm"];

export const ALL_FILE_TYPES: AcceptedFileType[] = [
  ...IMAGE_FILE_TYPES,
  ...VIDEO_FILE_TYPES,
];
