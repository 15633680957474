import { Text } from "@hackthenorth/north";
import { Link } from "north.js";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PageWrapper } from "src/shared/components";
import { Route as RouteConstants } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";

export const HomePage = () => {
  const { logIn, isAuthenticated } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RouteConstants.LIVEGRAPHS);
  }, [navigate]);

  return (
    <PageWrapper pageTitle="Home">
      {isAuthenticated ? (
        <div>hello world</div>
      ) : (
        <Text>
          You&apos;re currently logged out.{" "}
          <Link onClick={logIn}>Click here to log in</Link>.
        </Text>
      )}
    </PageWrapper>
  );
};
