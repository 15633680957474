import { TextInput } from "@hackthenorth/north";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { useDateAutoComplete } from "src/shared/hooks";

const StyledTextInput = styled(TextInput)`
  max-width: 160px;
`;

const DateTextInput: React.FC<ComponentPropsWithoutRef<"input">> = ({
  onChange,
  onKeyDown,
  placeholder = "YYYY-MM-DD",
  ...rest
}) => {
  const { onAutoComplete, onKeyDown: onAutoCompleteKeyDown } =
    useDateAutoComplete();
  return (
    <StyledTextInput
      type="text"
      maxLength={10}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onAutoComplete(e);
        onChange && onChange(e);
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        onAutoCompleteKeyDown(e);
        onKeyDown && onKeyDown(e);
      }}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default DateTextInput;
