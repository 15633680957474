import React from "react";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import { theme } from "twin.macro";

import { useUserContext } from "src/shared/contexts";

/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */

const MessageText = styled.p`
  color: ${({ theme }) => theme.globalConstants.color.white};
`;

export const successToast = (message: string) => toast.success(message);

export const errorToast = (message: string, resolution?: "login" | "support") =>
  toast.error(() => {
    const { logIn } = useUserContext();

    return (
      <div tw="px-4">
        <MessageText>{message}</MessageText>
        {resolution === "login" ? (
          <p tw="m-0">
            Please{" "}
            <a
              tw="font-bold cursor-pointer hover:(underline)"
              onClick={() => logIn()}
              role="button"
              tabIndex={0}
            >
              login
            </a>{" "}
            to continue.
          </p>
        ) : resolution === "support" ? (
          <p tw="m-0">
            Please contact{" "}
            <a
              tw="text-text-danger font-bold cursor-pointer no-underline hover:(underline)"
              href="mailto:hello@hackthenorth.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@hackthenorth.com
            </a>
          </p>
        ) : null}
      </div>
    );
  });

const Toast = () => (
  <Toaster
    position="top-right"
    toastOptions={{
      success: {
        style: {
          padding: "12px 24px",
          backgroundColor: theme("colors.background.success"),
          fontFamily: theme("fontFamily.body"),
          fontSize: theme("fontSize.14"),
          color: theme("colors.white"),
          border: `1px solid ${theme("colors.border.success")}`,
          maxWidth: "500px",
        },
        iconTheme: {
          primary: theme("colors.text.success"),
          secondary: theme("colors.white"),
        },
      },
      error: {
        style: {
          padding: "12px 24px",
          backgroundColor: theme("colors.background.danger"),
          fontFamily: theme("fontFamily.body"),
          fontSize: theme("fontSize.14"),
          color: theme("colors.white"),
          border: `1px solid ${theme("colors.border.danger")}`,
          maxWidth: "500px",
        },
        iconTheme: {
          primary: theme("colors.text.danger"),
          secondary: theme("colors.white"),
        },
      },
    }}
  />
);
export default Toast;
