import { Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

interface Props {
  text?: string;
}

const Spinner: React.FC<Props> = ({ text }) => (
  <Container>
    <Loader />
    <Label>{text}</Label>
  </Container>
);

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary}; /* Blue */
`;

const Container = styled(Flex).attrs({
  align: "center",
  justify: "center",
  column: true,
})`
  row-gap: 20px;
  min-height: 500px;
`;

const Loader = styled.div`
  border: 2.5px solid rgba(0, 0, 0, 0); /* Light grey */
  border-top: 2.5px solid
    ${({ theme }) => theme.globalConstants.color.textTertiary}; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 500ms linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
