import React from "react";
import { styled } from "twin.macro";

import {
  colors,
  expressions,
  expressions_light,
  accessories,
} from "./BoatOptions";

const ExpressionContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  z-index: 20;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
`;

const AccessoryContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  z-index: 20;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
`;

const expressionPlacements = {
  smile: {
    left: 60,
    top: 76,
  },
  joy: {
    left: 59,
    top: 78,
  },
  eyepatch: {
    left: 44,
    top: 60,
  },
  glasses: {
    left: 29,
    top: 48,
  },
};

const accessoryPlacements = {
  flag: {
    left: 79,
    top: -16,
  },
  plant: {
    left: 48,
    top: -30,
  },
  pride: {
    left: 75,
    top: -24,
  },
  boat_hat: {
    left: 47,
    top: -7,
  },
  beret: {
    left: 46,
    top: -3,
  },
  egg: {
    left: 49,
    top: 0,
  },
  pirate_hat: {
    left: 39,
    top: -6,
  },
  cowboy_hat: {
    left: 43,
    top: -16,
  },
};

const SpeechBubble = styled.div<{ show: boolean; color: string }>`
  background: ${({ color }) => color};
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  left: 0;
  transition: transform 300ms cubic-bezier(0.47, -0.44, 0.49, 1.46),
    opacity 300ms;
  transform: scale(${({ show }) => (show ? 1 : 0)}) translate(-85%, -70%);
  transform-origin: bottom right;
  opacity: ${({ show }) => (show ? 1 : 0)};
  /* generated with https://projects.verou.me/bubbly */
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 81%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: ${({ color }) => color};
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -8px;
  }
`;

const SpeechBubble2 = styled.div<{ show: boolean }>`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: -40px;
  transition: transform 300ms cubic-bezier(0.47, -0.44, 0.49, 1.46),
    opacity 300ms;
  transform: scale(${({ show }) => (show ? 1 : 0)}) translate(-85%, -70%);
  transform-origin: bottom right;
  opacity: ${({ show }) => (show ? 1 : 0)};
  /* generated with https://projects.verou.me/bubbly/ */
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 81%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #ffffff;
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -8px;
  }
`;

const CustomBoat: React.FC<{
  color: string;
  expression: string;
  accessory: string;
  speechBubbleMarkup?: React.ReactNode;
  speechBubbleColor?: string;
  AltSpeechBubble?: boolean;
}> = ({
  color,
  expression,
  accessory,
  speechBubbleMarkup,
  speechBubbleColor = "#c8d9eb",
  AltSpeechBubble = false,
}) => {
  const expressionInfo = expressionPlacements[expression];
  const accessoryInfo = accessoryPlacements[accessory];

  return (
    <div tw="relative z-0" aria-hidden>
      <div>{colors[color]}</div>
      <ExpressionContainer {...expressionInfo}>
        {(color === "plaid" && expressions_light[expression]) ||
          expressions[expression]}
      </ExpressionContainer>
      <AccessoryContainer {...accessoryInfo}>
        {accessory === "boat_hat"
          ? accessories[accessory].normal[color]
          : accessories[accessory].normal}
      </AccessoryContainer>
      {AltSpeechBubble ? (
        <SpeechBubble2 show={!!speechBubbleMarkup}>
          {speechBubbleMarkup}
        </SpeechBubble2>
      ) : (
        <SpeechBubble show={!!speechBubbleMarkup} color={speechBubbleColor}>
          {speechBubbleMarkup}
        </SpeechBubble>
      )}
    </div>
  );
};

export default CustomBoat;
