import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

export const textSharedStyles = css`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  line-height: 28px;

  color: ${({ theme }) => theme.globalConstants.color.textDark};
  margin: 0;
  display: inline;
`;

export const textModifiers = {
  // font family
  heading: css`
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
    line-height: normal;
    display: block;
    margin-bottom: 0.4em;
  `,

  // sizes
  title: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.title}px;
  `,
  h1: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h1}px;

    ${({ theme }) => theme.mediaQueries.largeMobile`
      font-size: ${theme.globalConstants.fontSize.hmobile}px;
    `}
  `,
  h2: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h2}px;
    ${({ theme }) => theme.mediaQueries.largeMobile`
      font-size: ${theme.globalConstants.fontSize.h2M}px;
    `}
  `,
  hmobile: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.hmobile}px;
  `,
  h3: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h3}px;
    ${({ theme }) => theme.mediaQueries.largeMobile`
      font-size: ${theme.globalConstants.fontSize.h3M}px;
    `}
  `,
  h4: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h4}px;
    ${({ theme }) => theme.mediaQueries.largeMobile`
      font-size: ${theme.globalConstants.fontSize.h4M}px;
    `}
  `,
  big: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    line-height: 29px;
  `,
  caption: css`
    font-size: 14px;
  `,
  "extra-small": css`
    font-size: 10px;
  `,
  subtle: css`
    font-size: ${({ theme }) =>
      theme.globalConstants.fontSize.bodySubtle}px !important;
    line-height: 22px;
  `,
  // colors
  error: css`
    color: ${({ theme }) => theme.globalConstants.color.textDanger};
    font-weight: 500;
    font-size: 16px;
  `,
  backgroundSuccess: css`
    color: ${({ theme }) => theme.globalConstants.color.backgroundSuccess};
  `,
  success: css`
    color: ${({ theme }) => theme.globalConstants.color.backgroundSuccess};
  `,
  black: css`
    color: ${({ theme }) => theme.globalConstants.color.black};
  `,
  blue: css`
    color: ${({ theme }) => theme.globalConstants.color.textPrimary};
  `,
  grey: css`
    color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  `,
  blueGrey: css`
    color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  `,
  brandSecondary: css`
    color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  `,
  textBody: css`
    color: ${({ theme }) => theme.globalConstants.color.textBody};
  `,
  lightGrey: css`
    color: ${({ theme }) => theme.globalConstants.color.textLight};
  `,
  backgroundWarningLight: css`
    color: ${({ theme }) => theme.globalConstants.color.backgroundWarningLight};
  `,
  brandPrimary: css`
    color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
  `,
  white: css`
    color: ${({ theme }) => theme.globalConstants.color.white};
  `,
  darkBlue: css`
    color: ${({ theme }) => theme.globalConstants.color.textDark};
  `,

  backgroundDanger: css`
    color: ${({ theme }) => theme.globalConstants.color.backgroundDanger};
  `,

  textDanger: css`
    color: ${({ theme }) => theme.globalConstants.color.textDanger};
  `,

  borderFocus: css`
    color: ${({ theme }) => theme.globalConstants.color.borderFocus};
  `,

  // other
  bold: css`
    font-weight: bold;
  `,
  medium: css`
    font-weight: 500;
  `,
  italic: css`
    font-style: italic;
  `,
  center: css`
    text-align: center;
  `,
  "lh-regular": css`
    line-height: 1.5;
  `,
  "lh-small": css`
    line-height: 1;
  `,
  "no-decoration": css`
    text-decoration: none;
  `,
  nowrap: css`
    white-space: nowrap;
  `,
  code: css`
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.code};
    color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
    background-color: ${({ theme }) =>
      theme.globalConstants.color.brandSecondary};
    border-radius: 4px;
    padding: 1px 4px;
  `,

  link: css`
    color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
    display: inline-block;
    text-decoration: underline;
    font-weight: 800;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 80%;
    }
  `,
};

export const textStyles: ThemeDefinition["text"] = {
  sharedStyles: textSharedStyles,
  modifiers: textModifiers,
};
