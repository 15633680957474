import { TextInput } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

// import { useSiteContext } from "src/contexts/SiteContext";
import { TWText } from "src/shared/styles";
import { XMarkIcon } from "src/static/icons";

type TTagInputProps = React.ComponentPropsWithRef<"input"> & {
  multi?: boolean;
  max?: number;
  options: string[];
  userOptions?: string[];
  setUserOptions?: (selected: string[]) => void;
  adding?: boolean;
  setAdding?: (adding: boolean) => void;
  selectedOptions: string[];
  setSelectedOptions: (selected: string[]) => void;
  canAdd?: boolean;
  errors?: string[];
  isRequired?: boolean;
  didEdit?: boolean;
};

type TTagProps = React.ComponentPropsWithRef<"div"> & {
  selected: boolean;
  error?: boolean;
};

const TagInput: React.FC<TTagInputProps> = ({
  multi,
  max,
  options,
  userOptions,
  setUserOptions,
  adding,
  setAdding,
  selectedOptions,
  setSelectedOptions,
  canAdd,
  errors = [],
  isRequired = false,
  didEdit = false,
  ...rest
}) => {
  const [inputWidth, setInputWidth] = useState(110);
  const [isFirst, setIsFirst] = useState(true);

  const toggleOption = (e: any, option: string) => {
    let newSelectedOptions: string[];
    e.target.blur();
    if (selectedOptions.includes(option)) {
      newSelectedOptions = selectedOptions.filter((o) => o !== option);
      if (userOptions && userOptions.includes(option)) {
        if (setUserOptions)
          setUserOptions(userOptions.filter((o) => o !== option));
      }
    } else {
      newSelectedOptions = multi ? [...selectedOptions, option] : [option];
    }
    if (max && newSelectedOptions.length > max) {
      return;
    }
    setSelectedOptions(newSelectedOptions);
  };

  const handleSubmit = (e: any) => {
    let newSelectedOptions: string[];
    let newUserOptions: string[] = [];
    if (
      selectedOptions.includes(e.target.value) ||
      e.target.value.trim() === "" ||
      (max && selectedOptions.length > max)
    ) {
      return;
    } else {
      if (userOptions) {
        newUserOptions = [...userOptions, e.target.value.trim()];
      }
      newSelectedOptions = [...selectedOptions, e.target.value.trim()];
    }
    if (max && newSelectedOptions.length > max) {
      return;
    }
    setSelectedOptions(newSelectedOptions);
    if (setUserOptions && newUserOptions) setUserOptions(newUserOptions);
    if (setAdding) setAdding(false);
    setInputWidth(110);
  };

  const hasError = () => isRequired && didEdit && errors.length;

  return (
    <div>
      <TagContainer {...rest} css={[TWText.body]}>
        {options.map((option: string) => {
          return (
            <Tag
              key={option}
              selected={selectedOptions.includes(option)}
              disabled={
                !!(
                  !selectedOptions.includes(option) &&
                  max &&
                  selectedOptions.length >= max
                )
              }
              error={!!(option.length > 20)}
            >
              {option}
              <XIcon
                cursor="pointer"
                onClick={(e: any) => toggleOption(e, option)}
              />
            </Tag>
          );
        })}
        {userOptions &&
          userOptions.map((option: string) => {
            return (
              <Tag
                selected={selectedOptions.includes(option)}
                disabled={
                  !!(
                    !selectedOptions.includes(option) &&
                    max &&
                    selectedOptions.length >= max
                  )
                }
                key={option}
                onClick={(e: any) => toggleOption(e, option)}
              >
                {option}
              </Tag>
            );
          })}
        {adding && (
          <TextInputWrapper>
            <TextInputTag
              id="newInput"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") handleSubmit(e);
              }}
              onChange={(e: any) => {
                const font = "12pt satoshi";
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                if (!context) return;
                context.font = font;
                const { width } = context.measureText(e.target.value);
                setInputWidth(width + 52);
                setIsFirst(false);
              }}
              onBlur={(e: any) => handleSubmit(e)}
              width={inputWidth}
              placeholder={isFirst ? "ex. Gaming" : ""}
            />
          </TextInputWrapper>
        )}
        {canAdd && (
          <Tag
            key="plus"
            selected={false}
            disabled={!canAdd}
            onClick={() => {
              if (setAdding) setAdding(true);
            }}
          >
            +
          </Tag>
        )}
      </TagContainer>
      {hasError() ? (
        <div>Please select at least one option.</div>
      ) : (
        <div tw="h-24 w-10" />
      )}
    </div>
  );
};

export default TagInput;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tag = styled.button<TTagProps>`
  display: flex;
  height: 42px;
  padding: 8px 16px;
  margin: 8px 8px 8px 0px;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  border-radius: 8px;
  border: ${({ error, theme }) =>
    error
      ? `2px solid ${theme.globalConstants.color.borderDanger}`
      : `2px solid ${theme.globalConstants.color.borderGray}`};
  align-items: center;
`;

const XIcon = styled(XMarkIcon)`
  width: 10px;
  height: 10px;
  margin-left: 8px;
`;

const TextInputWrapper = styled.div`
  width: fit-content;
`;

const TextInputTag = styled(TextInput)<{ width: number }>`
  width: ${({ width }) => (width ? `${width}px` : "100px")};
  height: 42px;
  padding: 8px 16px;
  margin: 8px 8px 8px 0px;
  border-radius: 8px;
`;
