import React from "react";
import styled from "styled-components";

import { deviceBreakpoints } from "src/theme/deviceBreakpoints";

import Card from "../Card";

const ContentCard = styled(Card).attrs({ color: "white" })`
  position: relative;
  padding: 32px;
  margin: auto;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundWhite};
  max-width: ${deviceBreakpoints.large}px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 16px;
  `}
`;

export default React.memo(ContentCard);
