import { Flex, Spacer, Text } from "@hackthenorth/north";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { PageWrapper } from "src/shared/components";
import { BlueHatBeaverImg, HourglassMiniImg } from "src/static/img";

import { getTimeLeft } from "./utils";

const StyledPageWrapper = styled(PageWrapper)`
  background: ${({ theme }) => theme.globalConstants.color.white};
  > div {
    height: 100%;
    max-width: none;
  }
`;

const BlueHatBeaver = styled.img`
  width: 150px;
`;

const Hourglass = styled.img`
  width: 200px;
`;

const Container = styled(Flex)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%);

  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundWarningLight};
  width: 600px;
  height: 600px;
  border-radius: 50%;
`;

const ComingSoon: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <StyledPageWrapper>
      <Container align="center" justify="center">
        <Flex column align="center">
          <Flex align="center">
            <BlueHatBeaver src={BlueHatBeaverImg} aria-hidden alt="Beaver" />
            <Hourglass src={HourglassMiniImg} aria-hidden alt="Hourglass" />
          </Flex>
          <Spacer height={48} />
          <Text mods="heading h2 bold">
            Coming soon on Friday, January 15th
          </Text>
          <Text mods="heading h1 brandPrimary">{timeLeft}</Text>
        </Flex>
      </Container>
    </StyledPageWrapper>
  );
};

export default ComingSoon;
