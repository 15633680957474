import React from "react";
import styled from "styled-components";

import { InfoTag } from "src/shared/components";

const HighPriority = styled(InfoTag).attrs({
  mods: "subtle medium nowrap white ",
})`
  padding: 4px 12px;
  background: ${({ theme }) => theme.globalConstants.color.backgroundDanger};
`;

const MediumPriority = styled(InfoTag).attrs({
  mods: "subtle medium nowrap white ",
})`
  padding: 4px 12px;
  background: ${({ theme }) => theme.globalConstants.color.brandPrimary};
`;

const LowPriority = styled(InfoTag).attrs({
  mods: "subtle medium nowrap white ",
})`
  padding: 4px 12px;
  background: ${({ theme }) => theme.globalConstants.color.backgroundSuccess};
`;

export const HighPriorityPill = <HighPriority>High</HighPriority>;
export const MediumPriorityPill = <MediumPriority>Medium</MediumPriority>;
export const LowPriorityPill = <LowPriority>Low</LowPriority>;

interface PriorityPillProps {
  priority: number;
}

const PriorityPill: React.FC<PriorityPillProps> = ({ priority }) => {
  if (priority === 0) {
    return LowPriorityPill;
  } else if (priority === 1) {
    return MediumPriorityPill;
  } else if (priority === 2) {
    return HighPriorityPill;
  }
  return <InfoTag />;
};

export default PriorityPill;
