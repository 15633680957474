/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetSponsorIndividualQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type GetSponsorIndividualQuery = {
  sponsorIndividual: Pick<
    Types.SponsorIndividual,
    | "id"
    | "user_id"
    | "sponsor_company_slug"
    | "first_name"
    | "last_name"
    | "preferred_name"
    | "role_in_company"
  >;
};

export const GetSponsorIndividualDocument = gql`
  query getSponsorIndividual($email: String!) {
    sponsorIndividual(email: $email) {
      id
      user_id
      sponsor_company_slug
      first_name
      last_name
      preferred_name
      role_in_company
    }
  }
`;

/**
 * __useGetSponsorIndividualQuery__
 *
 * To run a query within a React component, call `useGetSponsorIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorIndividualQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetSponsorIndividualQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorIndividualQuery,
    GetSponsorIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorIndividualQuery,
    GetSponsorIndividualQueryVariables
  >(GetSponsorIndividualDocument, baseOptions);
}
export function useGetSponsorIndividualLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorIndividualQuery,
    GetSponsorIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorIndividualQuery,
    GetSponsorIndividualQueryVariables
  >(GetSponsorIndividualDocument, baseOptions);
}
export type GetSponsorIndividualQueryHookResult = ReturnType<
  typeof useGetSponsorIndividualQuery
>;
export type GetSponsorIndividualLazyQueryHookResult = ReturnType<
  typeof useGetSponsorIndividualLazyQuery
>;
export type GetSponsorIndividualQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorIndividualQuery,
  GetSponsorIndividualQueryVariables
>;
