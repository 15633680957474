import React, { useEffect, useState } from "react";
import styled from "styled-components";

export interface FlipCardProps {
  value: string;
  onFlipStart?: (from: string, to: string) => void;
  onFlipFinish?: (from: string, to: string) => void;
}

/**
 * NOTE: the interval between changes in `value` must be longer than the transition duration (400ms).
 */
const FlipCard: React.FC<FlipCardProps> = ({
  value,
  onFlipStart,
  onFlipFinish,
}) => {
  const [curValue, setCurValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);

  const [flipped, setFlipped] = useState(false);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (value !== curValue) {
      /**
       * Sometimes due to browser circumstances, the `onAnimationEnd` callback will not
       * be run (browser optimization when tabbing away?). If we don't set flipped back
       * to false before setting it to true, it won't trigger the animation again
       */
      if (flipped) setFlipped(false);

      setShow(true);
      setCurValue(value);
      setTimeout(() => setFlipped(true), 40);

      onFlipStart?.(prevValue, value);
    }
  }, [value, curValue, prevValue, onFlipStart, flipped]);

  return (
    <Container>
      <div className="top-background card">{curValue}</div>
      {show && (
        <div
          className={`flip${flipped ? " flipped" : ""}`}
          onTransitionEnd={() => {
            setShow(false);
            setFlipped(false);
            setPrevValue(curValue);

            onFlipFinish?.(prevValue, curValue);
          }}
        >
          <div className="flip-foreground card">{prevValue}</div>
          <div className="flip-background card">{curValue}</div>
        </div>
      )}

      <div className="bottom-background card">{prevValue}</div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 80px;
  height: 90px;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.medium`
    width: 48px;
    height: 54px;

    & .card {
      font-size: 42px !important;
    }
  `}

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 32px;
    height: 36px;

    & .card {
      font-size: 26px !important;
    }
  `}

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 24px;
    height: 27px;

    & .card {
      font-size: 18px !important;
    }
  `}

  & .card {
    font-size: 52px;
    color: ${({ theme }) => theme.globalConstants.color.textDark};
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
    font-weight: bold;
    line-height: 0;
    overflow: hidden;
    background: #c8d9eb;
    display: flex;
    justify-content: center;
  }

  & .top-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(50% - 1px);
    z-index: 0;
    align-items: flex-end;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  & .flip {
    position: relative;
    width: 100%;
    height: 50%;
    z-index: 1;
    transform: rotateX(0);
    transform-style: preserve-3d;
    transform-origin: bottom;
    transition: transform 400ms ease-in-out;
    padding-bottom: 2px;
    &.flipped {
      transform: rotateX(-180deg);
    }

    & .flip-foreground {
      align-items: flex-end;
      position: absolute;
      width: 100%;
      height: calc(100% - 1px);
      backface-visibility: hidden;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    & .flip-background {
      align-items: flex-start;
      position: absolute;
      width: 100%;
      height: calc(100% - 1px);
      backface-visibility: hidden;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      transform: rotateX(-180deg);
    }
  }

  & .bottom-background {
    position: absolute;
    width: 100%;
    height: calc(50% - 1px);
    bottom: 0;
    z-index: 0;
    align-items: flex-start;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export default FlipCard;
