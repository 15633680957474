import {
  Text,
  Spacer,
  Modal as NorthModal,
  UnstyledButton,
} from "@hackthenorth/north";
import React, { ReactElement, CSSProperties } from "react";
import tw, { styled } from "twin.macro";

import { Icon } from "src/shared/components";
import { TWText } from "src/shared/styles";

interface ModalProps extends React.ComponentPropsWithoutRef<"div"> {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  actions?: ReactElement;
  width?: string;
  height?: string;
  scroll?: boolean;
  overlayStyle?: CSSProperties;
  noSection?: boolean;
}

const XIconButton = styled(UnstyledButton)`
  position: absolute;
  top: 48px;
  right: 48px;
`;

const ModalContainer = styled(NorthModal)<{ width: string }>`
  background: white;
  border-radius: 8px;
  position: relative;
  max-width: ${({ width }) => (width ? width : "600px")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: 400px;

  &:focus {
    outline: none;
  }
`;

const Container = styled.div<{
  hasXIcon?: boolean;
  height: string | undefined;
  scroll: boolean;
}>`
  /* padding-right: ${({ hasXIcon }) => (hasXIcon ? "48px" : 0)}; */
  padding: 36px;
  ${({ height }) => height && `height: ${height}`};
  ${({ scroll }) => scroll && `overflow-y: auto`};
`;

const NO_OP = () => {};

const Modal: React.FC<ModalProps> = ({
  className,
  isOpen,
  onClose,
  title,
  actions,
  width,
  height,
  scroll = false,
  overlayStyle,
  children,
  noSection,
}) => {
  const hasCloseIcon = Boolean(onClose);
  return (
    <ModalContainer
      className={className}
      isOpen={isOpen}
      onClose={onClose || NO_OP}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      width={width}
      height={height}
      overlayStyle={overlayStyle}
      tw="relative"
    >
      {hasCloseIcon && (
        <XIconButton onClick={onClose}>
          <Icon name="x" width="12px" height="12px" strokeWidth="1.2px" />
        </XIconButton>
      )}
      <Container hasXIcon={hasCloseIcon} height={height} scroll={scroll}>
        {title && (
          <>
            <HeaderText hasCloseIcon={hasCloseIcon}>{title}</HeaderText>
            <Spacer height={16} />
          </>
        )}
        {children}
      </Container>

      {actions && (
        <>
          <Spacer height={100} />
          <FooterContainer noSection={noSection}>{actions}</FooterContainer>
        </>
      )}
    </ModalContainer>
  );
};

const FooterContainer = styled.div<{ noSection: boolean | undefined }>`
  ${tw`absolute right-0 bottom-0 px-40 py-24 bg-white w-full `}
  ${({ noSection }) => !noSection && tw`border-t border-border-gray`}
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: end; /* separate actions into left and right */
  align-items: center;
`;

const HeaderText = styled(Text).attrs<{ hasCloseIcon: boolean }>({})`
  ${TWText.h2}
  font-size: 20px !important;
  padding-right: ${({ hasCloseIcon }) => (hasCloseIcon ? "32px" : 0)};
  color: ${({ theme }) =>
    theme.globalConstants.color.borderGrayDark} !important;
`;

export default Modal;
