import { Text } from "@hackthenorth/north";
import React, { useEffect } from "react";
import styled from "styled-components";

import { useDeviceSize } from "src/shared/hooks/useDeviceSize";

import * as Types from "../../../api/types.generated";

import InfiniteCarousel from "./infiniteCarousel";

interface ResponseCarouselProps {
  responses: Array<Pick<Types.SignageAnswer, "answer_id" | "value">>;
  loading: boolean;
  refetch: () => void;
  title: string;
  hide: boolean;
  maxLines?: number;
}

export const ResponseCarousel = ({
  responses,
  loading,
  refetch,
  title,
  hide,
  maxLines = 4,
}: ResponseCarouselProps) => {
  useEffect(() => {
    // Set up a refetch interval
    const intervalId = setInterval(() => {
      refetch();
    }, 10000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [refetch]);

  const isMediumOrSmaller = useDeviceSize("medium");

  const Carousel = ({ idx }: { idx: number }) => {
    return (
      <StyledInfiniteCarousel
        name={`${title}-response-carousel-${idx}`}
        stopOnHover={!isMediumOrSmaller}
        direction={idx % 2 === 0 ? "left" : "right"}
        aria-label="Response Carousel 1"
        paused={false}
        speed={20}
      >
        {responses.map((response) => (
          <ResponseWrapper key={response.answer_id}>
            <TextWrapper
              style={{
                WebkitLineClamp: maxLines,
                lineClamp: maxLines,
              }}
            >
              <Text mods="textBody bold" style={{ whiteSpace: "pre-wrap" }}>
                {response.value}
              </Text>
            </TextWrapper>
          </ResponseWrapper>
        ))}
      </StyledInfiniteCarousel>
    );
  };

  return (
    <>
      <Text mods="heading h1 bold">{title}</Text>
      <Text mods="heading h3">Other Hackers Said&hellip;</Text>
      {!loading &&
        responses.length > 0 &&
        (responses.length > 2 ? (
          <div
            style={{
              marginTop: "48px",
              opacity: hide ? 0 : 1,
              transition: "all .3s",
            }}
          >
            <Carousel idx={0} />
            <Carousel idx={1} />
          </div>
        ) : (
          <StyledContainer>
            {responses.slice(0, 1).map((response) => (
              <ResponseWrapper key={response.answer_id}>
                <TextWrapper>
                  <Text mods="textBody bold" style={{ whiteSpace: "pre-wrap" }}>
                    {response.value}
                  </Text>
                </TextWrapper>
              </ResponseWrapper>
            ))}
          </StyledContainer>
        ))}
    </>
  );
};

const ResponseWrapper = styled.div`
  width: 300px;
  border-radius: 8px;
  border: 1px solid var(--north-border-gray, #d1d5db);
  padding: 16px;
  flex: 1;
  min-height: 100%;
  height: auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  display: box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
`;

const StyledInfiniteCarousel = styled(InfiniteCarousel)`
  width: 100%;
  margin-top: 20px;
  height: auto;
`;

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 48px;
  height: auto;
`;

export default ResponseCarousel;
