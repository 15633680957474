import { UnstyledButton } from "@hackthenorth/north";
import React from "react";
import styled, { css } from "styled-components";

import { Icon } from "..";

const CardContainer = styled(UnstyledButton)<{
  selected: boolean;
  isLoading?: boolean;
}>`
  background: ${({ selected, theme }) =>
    selected
      ? theme.globalConstants.color.brandSecondary
      : theme.globalConstants.color.white};
  border: 2px solid ${({ theme }) => theme.globalConstants.color.brandSecondary};
  border-radius: 8px;
  box-sizing: border-box;
  width: 150px;
  height: 104px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &,
  &:focus {
    cursor: ${({ isLoading }) => (isLoading ? "wait" : "pointer")};
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaQueries.largeMobile`
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 118px;
    width: 148px;
  `};
`;

const SharedCardSelectorStyles = css`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
`;

const CardSelectorBox = styled.div`
  ${SharedCardSelectorStyles}
  background: ${({ theme }) => theme.globalConstants.color.white};
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.globalConstants.color.brandSecondary};
`;

const CardSelectorIcon = styled(Icon)`
  ${SharedCardSelectorStyles}
`;

export type TSelectCardProps = {
  selected: boolean;
  isLoading?: boolean;
  onSelect: () => void;
};

const SelectCard: React.FC<TSelectCardProps> = ({
  selected,
  onSelect,
  children,
}) => {
  return (
    <CardContainer selected={selected} onClick={onSelect}>
      {selected ? (
        <CardSelectorIcon name="blue-checkmark" size="100%" />
      ) : (
        <CardSelectorBox />
      )}
      {children}
    </CardContainer>
  );
};

export default SelectCard;
