/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type SubmitMentorshipRequestMutationVariables = Types.Exact<{
  requestData: Types.ClaimCreateInput;
}>;

export type SubmitMentorshipRequestMutation = {
  createClaim: Pick<Types.Claim, "id" | "stage_id"> & {
    fields: Array<
      Pick<
        Types.FieldWithAnswer,
        "id" | "field_type" | "slug" | "valid_values" | "updated_at"
      > & {
        answer?: Types.Maybe<
          Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
        >;
      }
    >;
  };
};

export const SubmitMentorshipRequestDocument = gql`
  mutation SubmitMentorshipRequest($requestData: ClaimCreateInput!) {
    createClaim(data: $requestData) {
      id
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useSubmitMentorshipRequestMutation__
 *
 * To run a mutation, you first call `useSubmitMentorshipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMentorshipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMentorshipRequestMutation, { data, loading, error }] = useSubmitMentorshipRequestMutation({
 *   variables: {
 *      requestData: // value for 'requestData'
 *   },
 * });
 */
export function useSubmitMentorshipRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitMentorshipRequestMutation,
    SubmitMentorshipRequestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SubmitMentorshipRequestMutation,
    SubmitMentorshipRequestMutationVariables
  >(SubmitMentorshipRequestDocument, baseOptions);
}
export type SubmitMentorshipRequestMutationHookResult = ReturnType<
  typeof useSubmitMentorshipRequestMutation
>;
export type SubmitMentorshipRequestMutationResult =
  ApolloReactCommon.MutationResult<SubmitMentorshipRequestMutation>;
export type SubmitMentorshipRequestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SubmitMentorshipRequestMutation,
    SubmitMentorshipRequestMutationVariables
  >;
