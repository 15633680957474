import { Spacer, Flex, Link, Button, Text } from "@hackthenorth/north";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import {
  hoverStyles,
  focusStyles,
  disabledStyles,
} from "src/theme/sharedStyles";
import "twin.macro";

interface FileLinkProps extends ComponentPropsWithoutRef<"a"> {
  file: HackerAPIFile;
  /**
   * Text to show as fallback if `file` does not have a name
   */
  label?: string;
  deleteFile?: () => void;
  onFileClick?: (e: React.MouseEvent<HTMLLinkElement>) => void;
}

const StyledLinkContainer = styled(Link)`
  display: flex !important;
  align-items: center;
  padding: 8px 32px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textDark};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
  background: ${({ theme }) => theme.globalConstants.color.white};
  margin-bottom: 8px;
`;

const NoStyleButton = styled(Button).attrs({ removeSharedStyles: true })`
  height: 24px;
  ${hoverStyles}
  ${disabledStyles}
  ${focusStyles}
`;

const FileLink: React.FC<FileLinkProps> = ({
  file,
  label = "File",
  deleteFile,
  onFileClick,
  ...rest
}) => (
  <>
    <Flex align="center">
      <StyledLinkContainer
        download
        href={file.uri || "#"}
        onClick={onFileClick}
        {...rest}
      >
        <Icon
          className="file-link-icon"
          name="file"
          color="white"
          width={24}
          height={30}
        />
        <Spacer width={8} />
        <Text mods="link">{file.name ?? label}</Text>
      </StyledLinkContainer>
      {deleteFile && (
        <>
          <Spacer width={16} />
          <NoStyleButton className="file-link-close-button">
            <Icon
              name="x"
              color="brandSecondary"
              onClick={deleteFile}
              width={20}
              height={20}
            />
          </NoStyleButton>
        </>
      )}
    </Flex>
  </>
);

export default FileLink;
