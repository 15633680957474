import { FileFieldAnswerType } from "src/api/types.generated";

import { Nullable } from "./typescript";

export interface Field<T> {
  answer?: Nullable<{
    value: T;
    updated_at: string;
  }>;
  field_type: string; // todo: better type for this
  slug?: Nullable<string>;
  updated_at: string; // datetime
  valid_values?: Nullable<string[]>;
}

export interface HackerAPIFile
  extends Omit<FileFieldAnswerType, "__typename"> {}

export const getFieldAnswer = <T>(
  fields: Field<T>[] = [],
  answerSlug: string
) => fields.find((f) => f.slug === answerSlug)?.answer?.value;

// convert individual into to fieldAnswers to an array to be able to update a claim
// todo: fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseState = (data: any) =>
  Object.entries(data).map(([field, answer]) => {
    return {
      slug: field,
      answer,
    };
  });

// Converts files we receive from HackerAPI to files we can submit to HackerAPI
export const convertToFileAnswers = (
  files: HackerAPIFile[] | HackerAPIFile | undefined
) => {
  if (!files) {
    return [];
  }
  if (!Array.isArray(files)) {
    return [files?.id];
  }
  return files?.map((file) => file.id);
};

/**
 * HackerAPI stores datetimes ISO 8601-compliant string with a UTC offset
 * Source: https://github.com/hackathon/hackerapi.com-v3/blob/master/src/v3/graphql/schemas/FieldAnswer.ts#L167-L177
 */
export type THackerAPIDatetime = string;
