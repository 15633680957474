import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { hoverStyles, disabledStyles } from "../sharedStyles";

export const sharedInputStyles = css`
  appearance: none;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundLight};
  padding: 0 16px;
  width: 100%;

  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  line-height: 29px;
  color: ${({ theme }) => theme.globalConstants.color.textDark};

  border: 1px solid ${({ theme }) => theme.globalConstants.color.brandSecondary};
  border-radius: 16px;
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
    outline: none;
  }

  &::placeholder {
    opacity: 0.75;
    color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  }

  ${hoverStyles}
  ${disabledStyles}
  transition: all 200ms;

  &:disabled {
    border-color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  }
`;

export const textInputStyles: ThemeDefinition["textInput"] = {
  sharedStyles: css`
    ${sharedInputStyles}
    height: 48px;
  `,
  modifiers: {
    error: css`
      border-color: ${({ theme }) =>
        theme.globalConstants.color.backgroundDanger};
    `,
  },
};
