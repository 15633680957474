import moment from "moment";

// Phase 1: Tue Sep 07 2021 00:00:00 GMT-0400 (Eastern Daylight Time)
export const PHASE_1_RELEASE_DATE = new Date(Date.UTC(2021, 8, 7, 4, 0, 0, 0));

// Phase 1: Tue Sep 10 2021 00:00:00 GMT-0400 (Eastern Daylight Time)
export const HARDWARE_TOOL_RELEASE_DATE = new Date(
  Date.UTC(2021, 8, 10, 4, 0, 0, 0)
);

// Phase 2: Sun Sep 12 2021 00:00:00 GMT-0400 (Eastern Daylight Time)
export const PHASE_2_RELEASE_DATE = new Date(Date.UTC(2021, 8, 12, 4, 0, 0, 0));

// Phase 3: Thu Sep 16 2021 00:00:00 GMT-0400 (Eastern Daylight Time)
export const PHASE_3_RELEASE_DATE = new Date(Date.UTC(2021, 8, 16, 4, 0, 0, 0));

// Opening ceremonies time: Fri Sep 17 2021 19:00:00 GMT-0400 (Eastern Daylight Time)
export const OPENING_CEREMONIES_DATE = new Date(
  Date.UTC(2021, 8, 17, 23, 0, 0, 0)
);

// Change homescreen to judging time: Sat Sep 18 2021 23:00:00 GMT-0400 (Eastern Daylight Time)
export const JUDGING_START = new Date(Date.UTC(2021, 8, 19, 3, 0, 0, 0));

export const GEAR_UP_START = moment.utc([2021, 8, 13, 14]).local(); // first gear up event is 10am EST; convert to UTC
export const GEAR_UP_END = moment.utc([2021, 8, 19]).local();
export const HACK_THE_NORTH_START = moment.utc([2021, 8, 17, 8, 30]).local();
export const HACK_THE_NORTH_END = moment.utc([2021, 8, 19, 23, 45]).local();
