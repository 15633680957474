/** Styles for Blueprint */
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import { ApolloProvider } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { log } from "@hackthenorth/analytics";
import { AuthProvider, North } from "@hackthenorth/north";
import { Worker as PDFWorker } from "@phuocng/react-pdf-viewer";
import { Button } from "north.js";
import React, { memo, useEffect, useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import {
  BrowserRouter,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import UAParser from "ua-parser-js";
import { QueryParamProvider } from "use-query-params";

import { client } from "src/api/client";
import {
  Route,
  QA,
  GlobalStyles,
  Toast,
  Icon,
  PageWrapper,
} from "src/shared/components";
import { BaseRoute, Route as RouteName } from "src/shared/constants/route";
import {
  UserContextProvider,
  PermissionsContextProvider,
  DataProvider,
} from "src/shared/contexts";
import { useUserContext, SidebarContextProvider } from "src/shared/contexts";
import { APP_ENV, IS_PRODUCTION } from "src/shared/utils/env";
import themeDef from "src/theme";
import NotFound from "src/views/404";
import AwSnap from "src/views/error/AwSnap";
import { HomePage } from "src/views/home";
import { PlaygroundRoutes } from "src/views/playground/routes";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import "north.js/dist/style.css";
import { BodyBold } from "./shared/styles";
import { mediaQueries } from "./shared/utils/responsive";
// import { IndivQuestions } from "./views/indiv-questions";
import { LiveGraphs } from "./views/live-graphs";

const queryClient = new QueryClient();

Bugsnag.start({
  apiKey: "fcc22e94006ad0b4c21cf5bd345065c5",
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line

const INDICATOR_COLOR: string = {
  production: "green",
  staging: "yellow",
  latest: "red",
  development: "grey",
}[APP_ENV];

const EnvIndicator = memo(function EnvIndicator() {
  return IS_PRODUCTION ? null : <Indicator />;
});

const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={RouteName.HOME} element={<HomePage />} />
      <Route path={RouteName.LIVEGRAPHS} element={<LiveGraphs />} />
      {/* <Route path={RouteName.INDIVQUESTIONS} element={<IndivQuestions />} /> */}
      <Route
        path={`${BaseRoute.PLAYGROUND}/*`}
        element={<PlaygroundRoutes />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AppContent = () => {
  const { logIn, logOut, isAuthenticated } = useUserContext();
  const [isLoggedOut, setIsLoggedOut] = useState(!isAuthenticated);

  useEffect(() => {
    const userAgent = new UAParser();

    const deviceType = userAgent.getDevice().type;

    /**
     * Possible device types: console, mobile, tablet, smarttv, wearable, embedded
     */
    log({
      identifier: deviceType ?? "desktop",
      duration: 0,
      startTime: 0,
      stream: "platform",
    });
    if (!("Notification" in window)) {
      console.debug("This browser does not support notifications.");
    } else {
      log({
        identifier: Notification?.permission,
        duration: 0,
        startTime: 0,
        stream: "notifications",
      });
    }
  }, []);

  return (
    <Container
      className="app"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Toast />
      <ChartsPageWrapper
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
          background: "#F3F4F6",
        }}
      >
        <NavbarContainer>
          <ChartsLogoContainer>
            <Icon name="charts-logo" size="title" />
            <BodyBold style={{ color: "#6B7280" }}>Charts</BodyBold>
          </ChartsLogoContainer>
          <Button
            color="tertiary"
            onClick={() => {
              isLoggedOut ? logIn() : logOut();
              setIsLoggedOut(true);
            }}
          >
            {isLoggedOut ? "Log In" : "Log Out"}
          </Button>
        </NavbarContainer>
        <MobileContainer>
          <div />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              color="tertiary"
              onClick={() => {
                isLoggedOut ? logIn() : logOut();
                setIsLoggedOut(true);
              }}
              style={{
                padding: "8px 24px",
              }}
            >
              {isLoggedOut ? "Log In" : "Log Out"}
            </Button>
            <Icon name="charts-logo" size="title" />
          </div>
        </MobileContainer>
      </ChartsPageWrapper>
      <AppRoutes />
    </Container>
  );
};

const App: React.FC = () => (
  <North themeDefinition={themeDef}>
    <PDFWorker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
              <ErrorBoundary FallbackComponent={AwSnap}>
                <AuthProvider production={IS_PRODUCTION}>
                  <UserContextProvider>
                    <DataProvider>
                      <PermissionsContextProvider>
                        <EnvIndicator />
                        <SidebarContextProvider>
                          <GlobalStyles />
                          <AppContent />
                        </SidebarContextProvider>
                        {/* <QA /> */}
                      </PermissionsContextProvider>
                    </DataProvider>
                  </UserContextProvider>
                </AuthProvider>
              </ErrorBoundary>
            </QueryParamProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ApolloProvider>
    </PDFWorker>
  </North>
);

const ChartsPageWrapper = styled(PageWrapper)`
  ${mediaQueries.largeMobile} {
    padding: 0px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const Indicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
  z-index: 1000;
  background-color: ${INDICATOR_COLOR};
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  ${mediaQueries.largeMobile} {
    display: none;
  }
`;

const ChartsLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MobileContainer = styled.div`
  display: none;
  border-bottom: 1px solid #e5e7eb;
  background: #f3f4f6;

  ${mediaQueries.largeMobile} {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 22px 28.015px 21px 32px;
  }
`;

export default App;
