import { Flex, Text, Spacer } from "@hackthenorth/north";
import React from "react";
import slugify from "slugify";
import { styled } from "twin.macro";

export type TRadioButtonData = {
  text: string;
  value: string;
};

type TRadioButtonsProps = React.ComponentPropsWithoutRef<"div"> & {
  name: string;
  options: TRadioButtonData[];
  value: string;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioButtons: React.FC<TRadioButtonsProps> = ({
  name,
  options,
  value,
  disabled,
  onChange,
  ...rest
}) => {
  return (
    <RadioButtonGroup {...rest}>
      {options.map((option) => (
        <>
          <Flex key={option.value}>
            <StyledInput
              id={slugify(option.value)}
              type="radio"
              name={name}
              value={option.value}
              checked={option.value === value}
              disabled={disabled}
              onChange={(e) => onChange(e)}
            />
            <label htmlFor={slugify(option.value)}>
              <Text>{option.text}</Text>
            </label>
          </Flex>
          <Spacer height={16} />
        </>
      ))}
    </RadioButtonGroup>
  );
};

const RadioButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  appearance: none;
  margin-left: 0;
  margin-right: 24px;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 3px;
  /* background-color only for content */
  background-color: transparent;
  background-clip: content-box;
  border: 2px solid ${({ theme }) => theme.globalConstants.color.textDark};
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.globalConstants.color.textDark};
  }
`;

export default RadioButtons;
