/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../types.generated";
export type GetOutlineFileQueryVariables = Types.Exact<{
  shareId: Types.Scalars["String"];
}>;

export type GetOutlineFileQuery = {
  outlineDocument: Pick<
    Types.OutlineDocument,
    "id" | "title" | "text" | "updatedAt"
  >;
};

export const GetOutlineFileDocument = gql`
  query GetOutlineFile($shareId: String!) {
    outlineDocument(share_id: $shareId) {
      id
      title
      text
      updatedAt
    }
  }
`;

/**
 * __useGetOutlineFileQuery__
 *
 * To run a query within a React component, call `useGetOutlineFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutlineFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutlineFileQuery({
 *   variables: {
 *      shareId: // value for 'shareId'
 *   },
 * });
 */
export function useGetOutlineFileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOutlineFileQuery,
    GetOutlineFileQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetOutlineFileQuery,
    GetOutlineFileQueryVariables
  >(GetOutlineFileDocument, baseOptions);
}
export function useGetOutlineFileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOutlineFileQuery,
    GetOutlineFileQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetOutlineFileQuery,
    GetOutlineFileQueryVariables
  >(GetOutlineFileDocument, baseOptions);
}
export type GetOutlineFileQueryHookResult = ReturnType<
  typeof useGetOutlineFileQuery
>;
export type GetOutlineFileLazyQueryHookResult = ReturnType<
  typeof useGetOutlineFileLazyQuery
>;
export type GetOutlineFileQueryResult = ApolloReactCommon.QueryResult<
  GetOutlineFileQuery,
  GetOutlineFileQueryVariables
>;
