import debounce from "lodash.debounce";
import React, { ComponentPropsWithoutRef, useRef, useState } from "react";
import useDimensions from "react-cool-dimensions";
import styled from "styled-components";

/**
 * Resize Observer Polyfill
 */
let resizeObserverPolyfill: any; // eslint-disable-line @typescript-eslint/no-explicit-any
(async () => {
  const isResizeObserverSupported = "ResizeObserver" in window;
  if (!isResizeObserverSupported) {
    const module = await import("@juggle/resize-observer");
    resizeObserverPolyfill = module.ResizeObserver;
  }
})();

interface CollapsibleProps extends ComponentPropsWithoutRef<"div"> {
  disabled?: boolean;
  showOverflow?: boolean;
  isOpen: boolean;
}

interface CollapsibleContainerProps {
  height: number;
  isOpen: boolean;
  showOverflow: boolean;
}

const CollapsibleContainer = styled.div<CollapsibleContainerProps>`
  overflow: ${({ showOverflow, isOpen }) =>
    showOverflow && isOpen ? "visible" : "hidden"};
  transition: max-height 0.3s ease-out, opacity 0.3s;
  max-height: ${({ isOpen, height }) => (isOpen ? height : "0")}px;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
`;

const Collapsible: React.FC<CollapsibleProps> = ({
  children,
  showOverflow = false,
  isOpen,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const debouncedSetHeight = useRef(
    debounce((newHeight: number) => setHeight(newHeight), 50)
  );

  useDimensions({
    ref,
    polyfill: resizeObserverPolyfill,
    onResize: ({ height: newHeight }) => debouncedSetHeight.current(newHeight),
  });

  return (
    <CollapsibleContainer
      isOpen={isOpen}
      height={height}
      showOverflow={showOverflow}
      aria-hidden={!isOpen}
      {...rest}
    >
      <div ref={ref}>{children}</div>
    </CollapsibleContainer>
  );
};
export default Collapsible;
