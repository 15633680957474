export enum DropzoneError {
  MIN_AMOUNT = "min-amount",
  MAX_AMOUNT = "max-amount",
  INVALID_FORMAT = "invalid-format",
}

export type ImageFileType = "svg" | "png" | "jpg" | "jpeg" | "pdf";
export type VideoFileType = "flac" | "mp4" | "ogg" | "webm";
export type PresentationFileType = "pptx" | "pdf";

export type AcceptedFileType =
  | ImageFileType
  | VideoFileType
  | PresentationFileType;
