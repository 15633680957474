import React, { useState, useCallback, useRef } from "react";
import Select, { Props, OptionTypeBase, OptionsType } from "react-select";
import Creatable from "react-select/creatable";
import styled, { css } from "styled-components";

import MultiValueRemove from "./MultiValueRemove";
import { useDynamicDropdownStyles } from "./useDynamicDropdownStyles";

export type TDropdownTagInputProps = Omit<Props, "theme"> & {
  creatable?: boolean;
  multi?: boolean;
  onEnter?: () => void;
  max?: number;
  error?: boolean;
};

export const selectStyles = css<TDropdownTagInputProps>`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
  line-height: 1.5;
  margin: 0;

  outline: none;

  .select__control {
    box-shadow: none;
    outline: none;
    min-height: 48px;
    border: 1px solid
      ${({ error, theme }) =>
        error
          ? theme.globalConstants.color.backgroundDanger
          : theme.globalConstants.color.brandPrimary};
    border-radius: 16px;
    background: ${({ theme }) => theme.globalConstants.color.backgroundLight};
    padding: 0px 4px;
    &:hover {
      cursor: pointer;
      border-color: ${({ theme }) =>
        theme.globalConstants.color.brandSecondary};
    }
    &::after {
      height: 4px;
      bottom: -4px;
    }
  }
  .select__control--is-focused {
    border-color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  }
  .select__control--is-disabled {
    background: ${({ theme }) => theme.globalConstants.color.backgroundGray};
    cursor: not-allowed;
    filter: grayscale(1);
  }
  .select__control--menu-is-open {
    border-radius: ${({ menuClosed }) => !menuClosed && "16px 16px 0px 0px"};
  }
  .select__option {
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-weight: 500;
    padding: 12px;
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    color: ${({ theme }) => theme.globalConstants.color.textDark};
    border-top: 0;
    cursor: pointer;
    margin: 8px;
    border-radius: 16px;
  }
  .select__option--is-focused {
    background: ${({ theme }) => theme.globalConstants.color.borderGray};
  }
  .select__option--is-selected {
    background: ${({ theme }) => theme.globalConstants.color.brandPrimary};
  }
  .select__value-container {
    outline: none;
    font-weight: 400;
    flex-wrap: wrap;
    padding: 8px 4px;
  }
  .select__menu {
    border-top: 0px;
    margin-top: -4px;
    padding: 0px;
    box-shadow: none;
    position: absolute;
    border-radius: 16px;
  }
  .select__menu-list {
    padding: 0;
    border: 1px solid #0e3460;
    border-radius: 0px 0px 16px 16px;
    max-height: 200px;
  }
  .select__indicator-separator {
    visibility: hidden;
  }
  .select__indicators {
    outline: none;
  }
  .select__single-value {
    color: ${({ theme }) => theme.globalConstants.color.textDark};
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    line-height: normal;
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  }
  .select__input {
    color: ${({ theme }) => theme.globalConstants.color.textDark};
    & input {
      font-weight: 400;
      font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
      line-height: normal;
    }
  }
  .select__placeholder {
    opacity: 0.75;
    color: ${({ theme }) => theme.globalConstants.color.textSecondary};
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-weight: 400;
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  }
  .select__multi-value {
    padding: 2px 24px;
    border-radius: 16px;
    background-color: ${({ theme }) =>
      theme.globalConstants.color.brandPrimary};
    color: white;
    align-items: center;
    margin-right: 8px;
    display: flex;
    justify-content: center;
  }
  .select__multi-value__label {
    color: white;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    font-weight: 500;
    line-height: 33px;
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  }
  .select__clear-indicator {
    visibility: hidden;
  }
  .select__multi-value__remove {
    background-color: ${({ theme }) =>
      theme.globalConstants.color.brandPrimary};
    margin-left: 16px;
    position: relative;
    padding: 0;
    &:focus,
    :hover {
      background-color: ${({ theme }) =>
        theme.globalConstants.color.brandPrimary};
      cursor: pointer;
      > svg path {
        stroke: ${({ theme }) => theme.globalConstants.color.brandSecondary};
      }
    }
  }
`;

const StyledSelect = styled(Select)<TDropdownTagInputProps>`
  ${selectStyles}
`;

const StyledCreatable = styled(Creatable)<TDropdownTagInputProps>`
  ${selectStyles}
`;

const DropdownTagInput: React.FC<TDropdownTagInputProps> = ({
  creatable = false,
  multi = false,
  onEnter,
  value,
  onChange,
  options,
  isSearchable = false,
  error = false,
  max,
  ...rest
}) => {
  const maxOptionsSelected = value && max ? value.length === max : false;
  const styles = useDynamicDropdownStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(
    value ? value.label : ""
  );
  const [displayedOptions, setDisplayedOptions] = useState<OptionTypeBase[]>(
    options || []
  );
  const [showValue, setShowValue] = useState<boolean>(true); //show value within input component

  const handleKeyPress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const menuClosed = displayedOptions.length === 0;
      if (e.key === "Enter" && menuClosed && onEnter) {
        onEnter();
      }
    },
    [displayedOptions, onEnter]
  );

  const handleOptionChange = (option: OptionTypeBase) => {
    // react-select hides the input on menu close if closeMenuOnSelect={true}, so keep that prop as false but display an empty menu
    // on change
    // ref: https://github.com/JedWatson/react-select/blob/b8298f4c43a54c19fce48536fbb1cf11a8b01c68/src/Select.js#L604
    setInputValue(option.label);
    onChange(option);
    setDisplayedOptions([]);
  };

  const handleInputChange = (text: string) => {
    // on input change, "open" the menu again
    setInputValue(text);
    setDisplayedOptions(options);
  };

  const Select = creatable ? StyledCreatable : StyledSelect;

  return (
    <Select
      controlShouldRenderValue={multi || !isSearchable || showValue}
      ref={inputRef}
      className="select"
      classNamePrefix="select"
      closeMenuOnSelect={false}
      createOptionPosition="first"
      styles={styles}
      components={{ MultiValueRemove }}
      onInputChange={handleInputChange}
      isMulti={multi}
      error={error}
      inputValue={isSearchable ? inputValue || "" : undefined}
      onKeyDown={handleKeyPress}
      onChange={multi ? onChange : handleOptionChange}
      onFocus={() => {
        setShowValue(false);
        setInputValue(value ? value.label : "");
      }}
      onBlur={() => {
        setShowValue(true);
      }}
      options={maxOptionsSelected ? [] : displayedOptions}
      value={value}
      isSearchable={isSearchable}
      isValidNewOption={(
        inputValue: string,
        selected: OptionsType<OptionTypeBase>,
        selectOptions: OptionsType<OptionTypeBase>
      ) =>
        !selected.some((opt) => opt.value === inputValue) &&
        selectOptions.every(
          (opt) => !opt.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      tabSelectsValue={false}
      noOptionsMessage={() => null}
      formatCreateLabel={(inputValue: string) =>
        inputValue ? `Add option "${inputValue}"` : "Add another option"
      }
      menuClosed={displayedOptions.length === 0}
      {...rest}
    />
  );
};

export default DropdownTagInput;
