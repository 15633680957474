import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import { IS_PRODUCTION } from "src/shared/utils/env";

import { useGetSignageQuestionAnswersQuery } from "./graphql/getSignageQuestionAnswers.generated";
import BarGraph from "./graphs/barGraph";
import ResponseCarousel from "./graphs/responseCarousel";
import WordCloud from "./graphs/wordCloud";

const GraphDisplays = () => {
  const [page, setPage] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [lastClickTime, setLastClickTime] = useState<number>(Date.now());

  const {
    data: lookingForwardData,
    loading: lookingForwardLoading,
    refetch: refetchLookingForward,
  } = useGetSignageQuestionAnswersQuery({
    variables: {
      question_id: IS_PRODUCTION ? 41 : 55,
    },
  });

  const {
    data: excitedToWorkData,
    loading: excitedToWorkLoading,
    refetch: refetchExcitedToWork,
  } = useGetSignageQuestionAnswersQuery({
    variables: {
      question_id: IS_PRODUCTION ? 42 : 56,
    },
  });

  const {
    data: musicData,
    loading: musicLoading,
    refetch: refetchMusic,
  } = useGetSignageQuestionAnswersQuery({
    variables: {
      question_id: IS_PRODUCTION ? 43 : 57,
    },
  });

  const graphs = [
    <BarGraph
      title="How many hours did you travel to attend Hack the North 2024?"
      xLabel="Hours Travelled"
      yLabel="Count"
      max={8}
      key={0}
    />,
    <BarGraph
      title="How many hours of sleep do you think you’re gonna get this weekend?"
      xLabel="Hours of Sleep"
      yLabel="Count"
      max={24}
      key={1}
    />,
    <WordCloud key={2} />,
    <ResponseCarousel
      responses={lookingForwardData?.getSignageQuestionAnswers ?? []}
      loading={lookingForwardLoading}
      refetch={refetchLookingForward}
      title="What are you looking forward to the most this weekend?"
      hide={transitioning || page !== 3}
      key={3}
    />,
    <ResponseCarousel
      responses={excitedToWorkData?.getSignageQuestionAnswers ?? []}
      loading={excitedToWorkLoading}
      refetch={refetchExcitedToWork}
      title="What are you excited to work on during the event?"
      hide={transitioning || page !== 4}
      key={4}
    />,
    <ResponseCarousel
      responses={musicData?.getSignageQuestionAnswers ?? []}
      loading={musicLoading}
      refetch={refetchMusic}
      title="Favourite song/genre of music to build to?"
      hide={transitioning || page !== 5}
      maxLines={1}
      key={5}
    />,
  ];

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage !== page && !transitioning) {
        setTransitioning(true);
        setPage(newPage);
        setLastClickTime(Date.now());
        setTimeout(() => {
          setTransitioning(false);
        }, 500);
      }
    },
    [page, transitioning]
  );

  useEffect(() => {
    const currentTime = Date.now();
    const timeSinceLastClick = currentTime - lastClickTime;

    if (timeSinceLastClick >= 20000) {
      handlePageChange((page + 1) % graphs.length);
    }

    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastClick = currentTime - lastClickTime;

      if (timeSinceLastClick >= 20000) {
        // If 20 seconds have passed, trigger a random box click
        handlePageChange((page + 1) % graphs.length);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [lastClickTime, handlePageChange, page, graphs.length]);

  return (
    <GraphWrapper>
      <CarouselWrapper page={page} transitioning={transitioning}>
        {graphs.map((graph, index) => (
          <CarouselSlide key={index}>{graph}</CarouselSlide>
        ))}
      </CarouselWrapper>
      <Pagination>
        {Array.from({ length: graphs.length }).map((_, index) => (
          <Circle
            key={index}
            isActive={index === page}
            onClick={() => handlePageChange(index)}
          />
        ))}
      </Pagination>
    </GraphWrapper>
  );
};

export default GraphDisplays;

const GraphWrapper = styled.div`
  margin-top: 40px;
  justify-content: space-between;
  background-color: white;
  padding: 24px 24px 0px 24px;
  border-radius: 8px;
  border: 1px solid var(--north-border-gray, #d1d5db);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.tablet`
    flex-direction: column; 
  `}
`;

const CarouselWrapper = styled.div<{ page: number; transitioning: boolean }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ page }) => `translateX(-${page * 100}%)`};
  width: 100%;
  min-height: 300px;
`;

const CarouselSlide = styled.div`
  min-width: 100%;
  flex-shrink: 0;
  padding: 24px;
  max-width: 100%;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 24px;
  z-index: 999999999999;
`;

const Circle = styled.div<{ isActive: boolean }>`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: ${({ isActive, theme }) =>
    isActive
      ? theme.globalConstants.color.backgroundPrimary
      : theme.globalConstants.color.backgroundPrimaryLight};
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 999999999999;
`;
