import { Field, RSVPData, TravelFormData } from "./types";

/* eslint-disable @typescript-eslint/camelcase */
export const DEFAULT_DATA: RSVPData & TravelFormData = {
  // Account
  // [Field.SAVED_EVENTS]: [],
  // [Field.INTERESTS]: [],

  // Personal Information
  [Field.LEGAL_NAME]: null,
  [Field.PREFERRED_NAME]: null,
  [Field.PRONOUNS]: null,
  [Field.PHONE_NUMBER]: null,
  [Field.WATERLOO_STUDENT_ID_NUMBER]: null,
  [Field.EMERGENCY_CONTACT_NAME]: null,
  [Field.EMERGENCY_CONTACT_RELATIONSHIP]: null,
  [Field.EMERGENCY_CONTACT_PHONE_NUMBER]: null,
  [Field.EMERGENCY_CONTACT_EMAIL]: null,
  [Field.MLH_SHARE_CONSENT]: null, // if the hacker opted into sharing application/registration info with MLH
  [Field.ACCESSIBILITY_NEEDS]: null,
  [Field.SHIRT_TYPE]: null,
  [Field.SHIRT_SIZE]: null,
  [Field.SWAG_SHIRT_ACCOMODATIONS]: null,
  [Field.FOOD_DIETARY_RESTRICTIONS]: null,

  // Not used
  // [Field.ADDRESS_LINE_ONE]: null,
  // [Field.ADDRESS_LINE_TWO]: null,
  // [Field.CITY]: null,
  // [Field.PROVINCE_STATE_REGION]: null,
  // [Field.COUNTRY]: null,
  // [Field.POSTAL_CODE]: null,
  // [Field.SWAG_PICKUP]: null,
  // [Field.MLH_EMAIL_CONSENT]: null, // if the hacker opted into receiving emails from MLH
  // [Field.WAIVER_CONSENT]: null,
  // [Field.RECORDING_CONSENT]: null,

  // Travel
  [Field.LOCATION]: null,
  [Field.TRAVEL_OPTION]: null,
  [Field.ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT]: null,
  [Field.REQUIRES_VISA]: null,
  [Field.VISA_ACKNOWLEDGEMENT]: null,

  // RSVP Event
  [Field.PROJECT_STREAM]: null,
  [Field.TECH_TALKS]: null,
  [Field.MEETUPS]: null,
  [Field.GITHUB]: null,
  [Field.INSTAGRAM]: null,
  [Field.FACEBOOK]: null,
  [Field.TWITTER]: null,
  [Field.LINKEDIN]: null,
  [Field.DISCORD_TAG]: null,
  [Field.TIKTOK]: null,
  [Field.BIOGRAPHY]: null,

  // RSVP Career
  [Field.RECRUITMENT_CONSENT]: null,
  [Field.RESUME]: null,
  [Field.CANADIAN_CITIZEN]: null,
  [Field.PERMANENT_RESIDENT]: null, // permanent resident of canada
  [Field.LOOKING_FOR_INTERNSHIPS]: null, // looking for internship opportunities
  [Field.LOOKING_FOR_FULLTIME]: null, // looking for full-time opportunities
  [Field.SPONSOR_INTEREST]: null,
  [Field.COFFEE_CHAT]: null,

  // RSVP Confirmation
  [Field.AGE_18]: null,
  [Field.TERMS_CONDITIONS]: null,
  [Field.GUARDIAN_NAME]: null,
  [Field.GUARDIAN_CONTACT]: null,
  [Field.WAIVER_CONSENT]: null,
  // temporary
  [Field.RSVP_DEADLINE]: "August 20, 2023 11:59 PM EDT",

  // travel form
  [Field.TRAVEL_PHONE_NUMBER]: null,
  [Field.BUS_COORDINATOR_INTEREST]: null,

  [Field.FRIDAY_FLIGHT_AIRLINE]: null,
  [Field.FRIDAY_FLIGHT_ARRIVAL_TIME]: null,
  [Field.FRIDAY_FLIGHT_NUMBER]: null,

  [Field.SUNDAY_FLIGHT_AIRLINE]: null,
  [Field.SUNDAY_FLIGHT_DEPARTURE_TIME]: null,
  [Field.SUNDAY_FLIGHT_NUMBER]: null,

  [Field.FLIGHT_CURRENCY]: "AUD",
  [Field.FLIGHT_AMOUNT]: null,
  [Field.REIMBURSEMENT_METHOD]: null,
  [Field.REIMBURSEMENT_RECIPIENT_NAME]: null,
  [Field.REIMBURSEMENT_RECIPIENT_ADDRESS]: null,
  [Field.REIMBURSEMENT_RECIPIENT_ETRANSFER]: null,
  [Field.REIMBURSEMENT_RECIPIENT_PAYPAL]: null,
  [Field.REIMBURSEMENT_ACKNOWLEDGEMENT]: null,

  [Field.FLIGHT_REIMBURSEMENT]: null,
  [Field.DEPARTURE_AIRPORT]: null,
  [Field.ARRIVAL_AIRPORT]: null,

  [Field.TRAVEL_RECEIPT]: null,
  [Field.VISA_TRACKING]: null,
  [Field.SHARE_EMAIL]: false,

  [Field.PERSONAL_RSVP_STAGE]: null,
  [Field.TRAVEL_RSVP_STAGE]: null,
  [Field.EVENT_RSVP_STAGE]: null,
  [Field.CAREER_RSVP_STAGE]: null,
  [Field.CONFIRMATION_RSVP_STAGE]: null,
};

console.log(DEFAULT_DATA);
