/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import tw, { styled, theme } from "twin.macro";

import "styled-components/macro";
// import { BaseRoute } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";
import { LogoGradient } from "src/static/img";

import { useSidebarContent } from "./useSidebarContent";

import { Button, Label, DropdownMenu, Modal } from "north.js";
import { JWTRole } from "@hackthenorth/north";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import Avvvatars from "avvvatars-react";
// import { useNavigate } from "react-router-dom";

export const getBadgeColor = (role: JWTRole) => {
  switch (role) {
    case JWTRole.SPONSOR:
      return "primary-light";
    case JWTRole.HACKER:
      return "secondary-light";
    case JWTRole.MENTOR:
      return "warning-light";
    case JWTRole.VOLUNTEER:
      return "warning-light";
    case JWTRole.ORGANIZER:
      return "danger-light";
    case JWTRole.ADMIN:
      return "danger-light";
    case JWTRole.WORKSHOP_LEAD:
      return "warning-light";
    default:
      return "secondary-light";
  }
};

const getTitle = (role: JWTRole) => {
  switch (role) {
    case JWTRole.SPONSOR:
      return "Sponsorship Dashboard";
    case JWTRole.HACKER:
      return "Hacker Dashboard";
    case JWTRole.MENTOR:
      return "Mentor Dashboard";
    case JWTRole.VOLUNTEER:
      return "Volunteer Dashboard";
    case JWTRole.ORGANIZER:
      return "Organizer Dashboard";
    case JWTRole.ADMIN:
      return "Organizer Dashboard";
    default:
      return "Hack The North";
  }
};

const Sidebar: React.FC = () => {
  // const navigate = useNavigate();
  const { logIn, logOut, isAuthenticated, isOrganizer, info, roles } =
    useUserContext();
  const { sections } = useSidebarContent();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const closeModal = () => setShowConfirmationModal(false);

  const primaryRole = isOrganizer ? JWTRole.ORGANIZER : roles[0] || "Guest";
  const avatar = <Avvvatars value={info?.name || "Guest"} size={24} />;

  const items = [
    // ...(!roles.includes(JWTRole.SPONSOR)
    //   ? [
    //       [
    //         {
    //           content: "Settings",
    //           action: () => {
    //             navigate(BaseRoute.SETTINGS);
    //           },
    //         },
    //       ],
    //     ]
    //   : []),
    [
      {
        content: "Log out",
        action: () => {
          setShowConfirmationModal(true);
        },
      },
      {
        content: (
          <Label
            color={getBadgeColor(primaryRole)}
            size="sm"
            tw="text-10 capitalize font-medium"
          >
            {primaryRole}
          </Label>
        ),
        disabled: true,
        action: () => {},
      },
    ],
  ];

  const Wrapper: React.FC = ({ children }) =>
    isAuthenticated ? (
      <DropdownMenu items={items} style={{ bottom: 50 }} tw="select-none">
        {children}
      </DropdownMenu>
    ) : (
      <div onClick={logIn}>{children}</div>
    );

  return (
    <div
      id="sidebar"
      tw="w-auto fixed left-0 flex flex-col h-screen bg-background-gray border-r border-border-gray"
      style={{ zIndex: 99 }}
    >
      <div tw="flex flex-col flex-1 h-0">
        <div tw="flex w-full justify-center py-32 px-32">
          <div tw="flex items-center w-160">
            <img src={LogoGradient} alt="htn-logo" tw="h-32 w-32 mr-16" />
            <div tw="text-text-secondary font-bold text-16">
              {getTitle(primaryRole)}
            </div>
          </div>
        </div>
        <nav tw="px-24 flex flex-col overflow-y-auto pb-24">
          {sections.map((section) => (
            <div key={section.title} tw="mt-16">
              <h3 tw="text-12 uppercase font-medium text-gray-500 mb-8">
                {section.title}
              </h3>
              <div tw="flex flex-col gap-2 whitespace-nowrap">
                {section.content.map((item) => {
                  const isCurrent =
                    item.href === "/"
                      ? window.location.pathname === item.href
                      : window.location.pathname.startsWith(item.href);
                  const Icon = isCurrent ? item.activeIcon : item.icon;
                  return (
                    <SidebarItem
                      key={item.name}
                      href={item.href}
                      current={isCurrent}
                      onClick={item.onClick}
                    >
                      <Icon style={{ height: 20, width: 20 }} />
                      <span tw="font-bold">{item.name}</span>
                    </SidebarItem>
                  );
                })}
              </div>
            </div>
          ))}
        </nav>
      </div>
      <Wrapper>
        <div tw="flex w-full justify-center px-20 py-8 border-t border-border-gray">
          <div tw="flex w-full items-center justify-between rounded-8 px-8 py-8 hover:bg-gray-200 cursor-pointer">
            {isAuthenticated ? (
              <>
                <div tw="flex items-center gap-8">
                  {avatar}
                  <span tw="font-bold text-text-secondary">{info?.name}</span>
                </div>
                <EllipsisVerticalIcon tw="w-20 h-20 text-text-secondary" />
              </>
            ) : (
              <span tw="font-bold text-text-secondary">Log in</span>
            )}
          </div>
        </div>
      </Wrapper>
      <Modal
        open={showConfirmationModal}
        onClose={closeModal}
        style={{ width: 350 }}
      >
        <Modal.Header>Log out</Modal.Header>
        <Modal.Body tw="w-full">Are you sure you want to log out?</Modal.Body>
        <Modal.Footer justify="end">
          <Button size="md" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              logOut();
              closeModal();
            }}
          >
            Log out
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const SidebarItem = styled.a<{ current: boolean }>`
  ${tw`flex items-start w-full rounded-8 py-8 px-24 gap-8`}
  color: ${(props) =>
    props.current ? theme`colors.text.primary` : theme`colors.text.secondary`};

  background: ${(props) =>
    props.current ? theme`colors.gray.200` : "transparent"};

  &:hover {
    color: ${(props) =>
      props.current
        ? theme`colors.text.primary`
        : theme`colors.text.secondary`};
    ${tw`bg-gray-200`}
    text-decoration: none;
  }
`;

export default Sidebar;
