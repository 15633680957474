// Synced with https://github.com/hackathon/hackerapi.com-v3/blob/master/src/pipelines/hackthenorth2022-mentorship-requests.json
export const MENTORSHIP_REQUEST_PIPELINE_SLUG =
  "hackthenorth2022-mentorship-requests";

export enum MentorshipRequestStage {
  OPEN = "open",
  CLAIMED = "claimed", // claimed by a mentor
  CLOSED = "closed", // resolved by mentors
  RESOLVED = "resolved", //resolved by mentees
}
