import { FileDropzone, Text, Spacer, Flex } from "@hackthenorth/north";
import React, { useState, useCallback, useMemo, forwardRef } from "react";
import { styled } from "twin.macro";

import { Icon } from "src/shared/components";

import { ALL_FILE_TYPES } from "./constants";
import { AcceptedFileType, DropzoneError } from "./types";

export interface DropzoneProps {
  min?: number;
  max?: number;
  readOnly?: boolean;
  // todo: fix type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: any; // any react element
  error?: boolean;
  acceptedFileTypes?: AcceptedFileType[];
  onFilesUpload: (files: File[]) => void;
  onError: (error: DropzoneError) => void;
  onBlur: () => void;
}

const FileDropzoneContent = styled(Flex)<{
  dragging: boolean;
  readOnly: boolean;
  error: boolean;
}>`
  cursor: pointer;
  padding: 40px 0;
  background-color: ${({ dragging, theme }) =>
    dragging
      ? theme.globalConstants.color.borderFocusLight
      : theme.globalConstants.color.white};
  transition: background-color 0.2s;
  filter: ${({ readOnly }) => readOnly && "grayscale(1)"};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D1D5DBFF' stroke-width='2' stroke-dasharray='15%2c 15' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");
`;

/* Prevents capturing from triggering an additional ClickEvent on the Dropzone*/
const CapturingPreventionWrapper = styled.div.attrs({
  onClick: (e) => e.preventDefault(),
})``;

const Dropzone = forwardRef(
  (
    {
      max,
      min,
      readOnly = false,
      label = "Drag and drop file",
      error = false,
      onError,
      onBlur,
      onFilesUpload,
      acceptedFileTypes = ALL_FILE_TYPES,
    }: DropzoneProps,
    ref
  ) => {
    const [dragging, setDragging] = useState(false);

    const accept = useMemo(() => {
      return acceptedFileTypes.map((fileType) => `.${fileType}`).join(",");
    }, [acceptedFileTypes]);

    /* See @https://stackoverflow.com/a/17355937 for file validation regex*/
    const isFileValid = useCallback(
      (file: File) => {
        const regex = new RegExp(
          "(" + acceptedFileTypes.join("|").replace(/\./g, "\\.") + ")",
          "i"
        );
        return regex.test(file.type);
      },
      [acceptedFileTypes]
    );

    const onDragChange = useCallback((dragging: boolean) => {
      setDragging(dragging);
    }, []);

    const onDrop = useCallback(
      (files: File[]) => {
        if (min && files.length < min) {
          onError(DropzoneError.MIN_AMOUNT);
          return;
        }

        if (max && files.length > max) {
          onError(DropzoneError.MAX_AMOUNT);
          return;
        }

        if (files.find((file) => !isFileValid(file))) {
          onError(DropzoneError.INVALID_FORMAT);
          return;
        }
        onFilesUpload(files);
      },
      [min, max, isFileValid, onError, onFilesUpload]
    );

    return (
      <CapturingPreventionWrapper>
        <FileDropzone
          className="file-dropzone"
          disabled={false}
          onDragChange={onDragChange}
          options={{
            onDrop,
            noKeyboard: true,
            multiple: min !== 1 || max !== 1,
            accept,
            disabled: readOnly,
            onDropRejected: () => console.debug("rejected"),
          }}
          ref={ref as React.RefObject<HTMLInputElement>}
          readOnly={readOnly}
        >
          <FileDropzoneContent
            column
            justify="center"
            align="center"
            className="file-dropzone-content"
            tw="cursor-pointer"
            dragging={dragging}
            readOnly={readOnly}
            error={error}
            onBlur={onBlur}
          >
            <Icon name="file-cloud" size="36px" />
            <Spacer height={8} />
            <Text mods="grey caption lh-regular" className="file-dropzone-text">
              {label}
            </Text>
            <Text mods={`grey lh-regular extra-small`}>
              {acceptedFileTypes.map((s) => s.toUpperCase()).join(", ")} up to
              10MB
            </Text>
          </FileDropzoneContent>
        </FileDropzone>
      </CapturingPreventionWrapper>
    );
  }
);

export default Dropzone;
