import { Theme, MediaQueryTemplates } from "@hackthenorth/north";

import { buttonStyles } from "./componentStyles/button";
import { dropdownInputStyles } from "./componentStyles/dropdownInput";
import { linkStyles } from "./componentStyles/link";
import { textStyles } from "./componentStyles/text";
import { textAreaInputStyles } from "./componentStyles/textAreaInput";
import { textInputStyles } from "./componentStyles/textInput";
import { deviceBreakpoints } from "./deviceBreakpoints";
import globalConstants from "./globalConstants";

/**
 * This merges our theme's interface with the default theme interface
 * and other declarations in styled-components, which provides
 * correct typing for the theme object in styled-components' ThemeProvider
 * (see https://www.styled-components.com/docs/api#typescript)
 */
declare module "styled-components" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {
    globalConstants: {
      color: {
        white: string;
        black: string;
        focus: string;
        transparent: "transparent";
        brandPrimary: string;
        brandSecondary: string;
        textDark: string;
        textBody: string;
        textSecondary: string;
        textTertiary: string;
        textLight: string;
        textPrimary: string;
        textSuccess: string;
        textDanger: string;
        textWarning: string;
        backgroundPrimaryLight: string;
        backgroundSuccessLight: string;
        backgroundDangerLight: string;
        backgroundWarningLight: string;
        backgroundPrimary: string;
        backgroundSuccess: string;
        backgroundDanger: string;
        backgroundWarning: string;
        backgroundWhite: string;
        backgroundLight: string;
        backgroundGray: string;
        backgroundGrayDark: string;
        backgroundDark: string;
        backgroundBlack: string;
        borderLight: string;
        borderGray: string;
        borderGrayDark: string;
        borderDark: string;
        borderPrimary: string;
        borderSuccess: string;
        borderDanger: string;
        borderWarning: string;
        borderFocus: string;
        borderFocusLight: string;
      };
      fontFamily: {
        heading: string;
        body: string;
        code: string;
      };
      borderRadius: {
        small: number;
        medium: number;
        large: number;
      };
      fontSize: {
        title: number;
        h1: number;
        hmobile: number;
        h2: number;
        h3: number;
        h4: number;
        body: number;
        bodySubtle: number;
        caption: number;

        h2M: number;
        h3M: number;
        h4M: number;
      };
      padding: {
        medium: number;
        link: number;
      };
      boxShadow: {
        halo: string;
        regular: string;
        dark: string;
        light: string;
      };
    };
    mediaQueries: MediaQueryTemplates;
  }
}

const componentStyles = {
  text: textStyles,
  link: linkStyles,
  textInput: textInputStyles,
  button: buttonStyles,
  textAreaInput: textAreaInputStyles,
  dropdownInput: dropdownInputStyles,
};

const themeDef = {
  ...componentStyles,
  globalConstants,
  deviceBreakpoints,
};

export default themeDef;
