/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetSponsorCompanyQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type GetSponsorCompanyQuery = {
  sponsorCompany: Pick<
    Types.SponsorCompany,
    | "id"
    | "slug"
    | "company_id"
    | "tier"
    | "stage"
    | "logo"
    | "primary_contact_name"
    | "primary_contact_email"
    | "invite_code"
    | "tc_accepted"
    | "coc_accepted"
  >;
};

export const GetSponsorCompanyDocument = gql`
  query getSponsorCompany($slug: String!) {
    sponsorCompany(slug: $slug) {
      id
      slug
      company_id
      tier
      stage
      logo
      primary_contact_name
      primary_contact_email
      invite_code
      tc_accepted
      coc_accepted
    }
  }
`;

/**
 * __useGetSponsorCompanyQuery__
 *
 * To run a query within a React component, call `useGetSponsorCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorCompanyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSponsorCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorCompanyQuery,
    GetSponsorCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorCompanyQuery,
    GetSponsorCompanyQueryVariables
  >(GetSponsorCompanyDocument, baseOptions);
}
export function useGetSponsorCompanyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorCompanyQuery,
    GetSponsorCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorCompanyQuery,
    GetSponsorCompanyQueryVariables
  >(GetSponsorCompanyDocument, baseOptions);
}
export type GetSponsorCompanyQueryHookResult = ReturnType<
  typeof useGetSponsorCompanyQuery
>;
export type GetSponsorCompanyLazyQueryHookResult = ReturnType<
  typeof useGetSponsorCompanyLazyQuery
>;
export type GetSponsorCompanyQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorCompanyQuery,
  GetSponsorCompanyQueryVariables
>;
