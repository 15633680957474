import { Text } from "@hackthenorth/north";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { OPENING_CEREMONIES_DATE } from "src/shared/constants/deadlines";
import { formatFlipTime, getTimeBetween } from "src/shared/utils/formatTime";

import FlipCard from "./FlipCard";

const SEPARATOR_INTERNAL = "_SEPARATOR_";

export const SEPARATOR = [SEPARATOR_INTERNAL];

const getCurrentTimeCardGroups = (date: Date) => {
  const [days, hours, minutes, seconds] = getTimeBetween(new Date(), date);

  if (days === 0) {
    // switch to use hours, minutes, seconds in last 24 hours
    return [
      { contents: formatFlipTime(hours).split(""), label: "hours" },
      { contents: SEPARATOR },
      { contents: formatFlipTime(minutes).split(""), label: "minutes" },
      { contents: SEPARATOR },
      { contents: formatFlipTime(seconds).split(""), label: "seconds" },
    ];
  }

  return [
    { contents: formatFlipTime(days).split(""), label: "days" },
    { contents: SEPARATOR },
    { contents: formatFlipTime(hours).split(""), label: "hours" },
    { contents: SEPARATOR },
    { contents: formatFlipTime(minutes).split(""), label: "minutes" },
  ];
};

type FlipCardClockProps = {
  date?: Date;
};

const FlipCardClock: React.FC<FlipCardClockProps> = ({
  date = OPENING_CEREMONIES_DATE,
}) => {
  const [currFlipClockTime, setCurrFlipClockTime] = useState(
    getCurrentTimeCardGroups(date)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrFlipClockTime(getCurrentTimeCardGroups(date));
    }, 1000);
    return () => clearInterval(timer);
  }, [date]);

  return (
    <Container>
      {currFlipClockTime.map(({ contents, label }, i) => (
        <GroupContainer key={i}>
          <Container className="content-container">
            {contents.map((content, i) =>
              content === SEPARATOR_INTERNAL ? (
                <Text key={i} mods="heading h1">
                  :
                </Text>
              ) : (
                <FlipCard key={i} value={content} />
              )
            )}
          </Container>
          {label && <Text>{label}</Text>}
        </GroupContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1em;
  margin: 12px 0;

  ${({ theme }) => theme.mediaQueries.tablet`
    column-gap: 0.5em;
  `}
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .content-container:only-child {
    padding-bottom: 1.5em;
  }
`;

export default FlipCardClock;
