/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetSignageQuestionAnswersQueryVariables = Types.Exact<{
  question_id: Types.Scalars["Float"];
}>;

export type GetSignageQuestionAnswersQuery = {
  getSignageQuestionAnswers: Array<
    Pick<Types.SignageAnswer, "answer_id" | "value">
  >;
};

export const GetSignageQuestionAnswersDocument = gql`
  query GetSignageQuestionAnswers($question_id: Float!) {
    getSignageQuestionAnswers(question_id: $question_id) {
      answer_id
      value
    }
  }
`;

/**
 * __useGetSignageQuestionAnswersQuery__
 *
 * To run a query within a React component, call `useGetSignageQuestionAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignageQuestionAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignageQuestionAnswersQuery({
 *   variables: {
 *      question_id: // value for 'question_id'
 *   },
 * });
 */
export function useGetSignageQuestionAnswersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSignageQuestionAnswersQuery,
    GetSignageQuestionAnswersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSignageQuestionAnswersQuery,
    GetSignageQuestionAnswersQueryVariables
  >(GetSignageQuestionAnswersDocument, baseOptions);
}
export function useGetSignageQuestionAnswersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSignageQuestionAnswersQuery,
    GetSignageQuestionAnswersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSignageQuestionAnswersQuery,
    GetSignageQuestionAnswersQueryVariables
  >(GetSignageQuestionAnswersDocument, baseOptions);
}
export type GetSignageQuestionAnswersQueryHookResult = ReturnType<
  typeof useGetSignageQuestionAnswersQuery
>;
export type GetSignageQuestionAnswersLazyQueryHookResult = ReturnType<
  typeof useGetSignageQuestionAnswersLazyQuery
>;
export type GetSignageQuestionAnswersQueryResult =
  ApolloReactCommon.QueryResult<
    GetSignageQuestionAnswersQuery,
    GetSignageQuestionAnswersQueryVariables
  >;
