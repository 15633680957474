import { Text, TextAreaInputProps } from "@hackthenorth/north";
import React, { useCallback, ChangeEvent } from "react";
import styled from "styled-components";

import { TWText } from "src/shared/styles";
import "twin.macro";

export interface WordLimitTextAreaProps
  extends Omit<TextAreaInputProps, "theme"> {
  wordLimit: number;
  error?: React.ReactNode;
}

const WordCounter = styled(Text)`
  left: 26px;
  bottom: 24px;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary} !important;
`;

const Container = styled.div`
  position: relative;
`;

const TextArea = styled.textarea<{ error: boolean }>`
  ${TWText.body}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;

  width: 100%;
  height: 175px;
  background: ${({ theme }) => theme.globalConstants.color.white};

  font-weight: 400;
  font-size: 14px;
  line-height: 160%;

  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: ${({ error }) =>
    error
      ? "rgba(203, 46, 46, var(--tw-border-opacity))"
      : "rgba(209, 213, 219, var(--tw-border-opacity))"};
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  --tw-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-offset-width: 0px;
    --tw-ring-color: rgba(117, 165, 238, 0.5);
    --tw-border-opacity: 1;
    border-color: rgba(117, 165, 238, var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`;

const WordLimitTextArea: React.FC<WordLimitTextAreaProps> = ({
  wordLimit,
  onChange,
  value,
  error,
  ...rest
}) => {
  const wordCount =
    (value as string | undefined)?.split(" ")?.filter((word) => !!word)
      .length || 0;

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const wordList = e.target.value.split(" ").filter((word) => !!word);
      if (wordList.length > wordLimit) {
        e.target.value = `${wordList.slice(0, wordLimit).join(" ")} `;
        return;
      }
      if (onChange) onChange(e);
    },
    [onChange, wordLimit]
  );

  return (
    <Container>
      <TextArea
        {...rest}
        value={value}
        onChange={internalOnChange}
        error={error as boolean}
      />
      <WordCounter mods="lightGrey">
        {wordCount}/{wordLimit} words
      </WordCounter>
    </Container>
  );
};

export default WordLimitTextArea;
