import { styled } from "twin.macro";

export interface CardProps {
  color?: string;
  hover?: boolean;
}
const Card = styled.div<CardProps>`
  background-color: ${({ theme, color = "white" }) =>
    theme.globalConstants.color[color]};
  border-radius: 8px;
  padding: 24px;
  transition: all 0.25s ease;

  ${({ hover }) =>
    hover &&
    `
      &:hover {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      }
    `}
`;

export default Card;
