import { JWTRole } from "@hackthenorth/north";
import React, { createContext, useContext } from "react";

import { SponsorCompanyTier } from "src/api/types.generated";
import {
  useUserContext,
  useSponsorContext,
  useHackerContext,
} from "src/shared/contexts";
import { IS_PRODUCTION } from "src/shared/utils/env";

import { HackerStage } from "../HackerContext/types";

import { Permission } from "./types";

export interface PermissionsContextState {
  isLoading: boolean;
  permissions: Permission[];
  hasPermission: (permission: Permission) => boolean;
  hasPermissions: (permissions?: Permission[]) => boolean;
}

const DEFAULT_STATE: PermissionsContextState = {
  isLoading: true,
  permissions: [],
  hasPermission: () => {
    throw new Error("No provider found");
  },
  hasPermissions: () => {
    throw new Error("No provider found");
  },
};

const PermissionsContext: React.Context<PermissionsContextState> =
  createContext(DEFAULT_STATE);

export const usePermissionsContext = () => useContext(PermissionsContext);

export const PermissionsContextProvider: React.FC = ({ children }) => {
  const { roles, isOrganizer } = useUserContext();
  const { loading: isSponsorLoading, company } = useSponsorContext();
  const { stage, isLoading: isHackerLoading } = useHackerContext();

  const hasPermission: PermissionsContextState["hasPermission"] = (
    permission: Permission
  ) => {
    if (isOrganizer) return true;

    // values that are shared across multiple permissions
    const isSponsor = roles.includes(JWTRole.SPONSOR);
    const isHacker = roles.includes(JWTRole.HACKER);
    const isVolunteer = roles.includes(JWTRole.VOLUNTEER);
    const isMentor = roles.includes(JWTRole.MENTOR);
    const isWorkshopLead = roles.includes(JWTRole.WORKSHOP_LEAD);

    switch (permission) {
      case Permission.SPONSOR:
        return isSponsor;

      case Permission.HACKER:
        return isHacker;

      case Permission.VOLUNTEER:
        return isVolunteer;

      case Permission.MENTOR:
        return isMentor;

      case Permission.WORKSHOP_LEAD:
        return isWorkshopLead;

      case Permission.SPONSOR_RECRUITMENT_ACCESS:
        return (
          isSponsor &&
          (company?.tier === SponsorCompanyTier.Silver ||
            company?.tier === SponsorCompanyTier.Gold)
        );

      case Permission.HACKER_RSVP:
        return (
          isHacker &&
          (stage === HackerStage.ACCEPTED ||
            stage === HackerStage.AUTO_ACCEPTED ||
            stage === HackerStage.WAITLISTED ||
            stage === HackerStage.WITHDRAWN ||
            stage === HackerStage.CONFIRMED ||
            stage === HackerStage.EXPIRED ||
            stage === HackerStage.NOT_ACCEPTED)
        );

      case Permission.HACKER_RSVP_NOT_RESTRICTED:
        return (
          isHacker &&
          (stage === HackerStage.ACCEPTED ||
            stage === HackerStage.AUTO_ACCEPTED ||
            stage === HackerStage.CONFIRMED)
        );

      case Permission.HACKER_EVENT_ACCESS:
        // return (
        //   isHacker &&
        //   (stage === HackerStage.CONFIRMED || stage === HackerStage.CHECKED_IN)
        // );
        return false;

      case Permission.VIEW_FULL_SCHEDULE:
        return true;

      case Permission.STAGING:
        return !IS_PRODUCTION;

      case Permission.ORGANIZER:
        return isOrganizer;

      default:
        throw new Error(`Invalid permission found: ${permission}`);
    }
  };

  const hasPermissions: PermissionsContextState["hasPermissions"] = (
    permissions: Permission[] = [],
    requireAll = true
  ) =>
    requireAll
      ? permissions.every((p) => hasPermission(p))
      : permissions.some((p) => hasPermission(p));

  const permissions: PermissionsContextState["permissions"] = Object.values(
    Permission
  ).filter((p) => hasPermission(p));

  const isLoading = isSponsorLoading || isHackerLoading;

  return (
    <PermissionsContext.Provider
      value={{ isLoading, permissions, hasPermission, hasPermissions }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
