import { Text } from "@hackthenorth/north";
import React, { Children, ReactElement } from "react";
import ReactMarkdown, { ReactMarkdownProps } from "react-markdown";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import styled from "styled-components";

import { Link, Divider } from "src/shared/components";

interface MarkdownDocumentProps {
  content: string;
  reactMarkdownProps?: ReactMarkdownProps;
}

const EmptyDiv = styled.div`
  position: relative;
  top: -100px;
`;

const MarkdownContainer = styled.section`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  line-height: 29px;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const renderers = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  thematicBreak: () => <Divider />,
  link: (props: any) => {
    if (!props) {
      console.error("Invalid markdown link");
      return <></>;
    }
    return (
      <Link href={props.href} newTab>
        {Children.map(
          props.children,
          (child: ReactElement) => child.props.value
        )}
      </Link>
    );
  },
  text: (props: any) => {
    if (!props) {
      console.error("Invalid markdown text");
      return <></>;
    }
    // Outline represents empty lines as `\\`, since markdown doesn't support consecutive newlines (see https://github.com/outline/outline/issues/1308)
    // so we need to remove them and represent them as line breaks
    const newlineMatcher = /(\\\n|^\\$)/g;
    const newlines: string[] = props.value.match(newlineMatcher) || [];
    const sanitizedValue = props.value.replace(newlineMatcher, "");

    return (
      <>
        {newlines.map((_, i) => (
          <br key={i} />
        ))}
        <Text>{sanitizedValue}</Text>
      </>
    );
  },
  /* eslint-disable jsx-a11y/alt-text */
  image: (props: any) => {
    return <img {...props} style={{ maxWidth: "80%" }} />;
  },
  heading: (props: any) => {
    if (!props) {
      console.error("Invalid markdown heading");
      return <></>;
    }
    const text: string = props?.children[0]?.props?.value ?? "";

    return (
      <>
        <Text mods={`heading h${props.level}`}>
          <EmptyDiv id={text.toLowerCase().split(" ").join("-") ?? ""} />
          {text}
        </Text>
      </>
    );
  },
  inlineCode: (props: any) => {
    if (!props) {
      console.error("Invalid markdown inline code");
      return <></>;
    }
    // todo: render this as a `code` element
    return <Text mods="code">{props.value}</Text>;
  },
  code: (props: any) => {
    if (!props) {
      console.error("Invalid markdown code");
      return <></>;
    }
    return (
      <SyntaxHighlighter
        language="typescript"
        style={prism}
        customStyle={{ borderRadius: "10px", fontSize: "14px" }}
      >
        {props.value}
      </SyntaxHighlighter>
    );
  },
  root: (props: any) => {
    if (!props) {
      console.error("Invalid root markdown");
      return <></>;
    }
    return (
      <MarkdownContainer className="markdown-container">
        {props.children}
      </MarkdownContainer>
    );
  },
};

const MarkdownDocument: React.FC<MarkdownDocumentProps> = ({
  content,
  reactMarkdownProps,
}) => (
  <ReactMarkdown
    {...reactMarkdownProps}
    source={content}
    renderers={renderers}
  />
);
export default MarkdownDocument;
