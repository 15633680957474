/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetSignageAnswerFrequencyQueryVariables = Types.Exact<{
  title?: Types.Maybe<Types.Scalars["String"]>;
  question_id?: Types.Maybe<Types.Scalars["Float"]>;
}>;

export type GetSignageAnswerFrequencyQuery = Pick<
  Types.Query,
  "getSignageAnswerFrequency"
>;

export const GetSignageAnswerFrequencyDocument = gql`
  query GetSignageAnswerFrequency($title: String, $question_id: Float) {
    getSignageAnswerFrequency(title: $title, question_id: $question_id)
  }
`;

/**
 * __useGetSignageAnswerFrequencyQuery__
 *
 * To run a query within a React component, call `useGetSignageAnswerFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignageAnswerFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignageAnswerFrequencyQuery({
 *   variables: {
 *      title: // value for 'title'
 *      question_id: // value for 'question_id'
 *   },
 * });
 */
export function useGetSignageAnswerFrequencyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSignageAnswerFrequencyQuery,
    GetSignageAnswerFrequencyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSignageAnswerFrequencyQuery,
    GetSignageAnswerFrequencyQueryVariables
  >(GetSignageAnswerFrequencyDocument, baseOptions);
}
export function useGetSignageAnswerFrequencyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSignageAnswerFrequencyQuery,
    GetSignageAnswerFrequencyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSignageAnswerFrequencyQuery,
    GetSignageAnswerFrequencyQueryVariables
  >(GetSignageAnswerFrequencyDocument, baseOptions);
}
export type GetSignageAnswerFrequencyQueryHookResult = ReturnType<
  typeof useGetSignageAnswerFrequencyQuery
>;
export type GetSignageAnswerFrequencyLazyQueryHookResult = ReturnType<
  typeof useGetSignageAnswerFrequencyLazyQuery
>;
export type GetSignageAnswerFrequencyQueryResult =
  ApolloReactCommon.QueryResult<
    GetSignageAnswerFrequencyQuery,
    GetSignageAnswerFrequencyQueryVariables
  >;
