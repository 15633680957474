/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetSignageCountStatsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSignageCountStatsQuery = {
  signageStats: Pick<
    Types.SignageStat,
    | "participating_hackers"
    | "responses_submitted"
    | "checked_in_attendees"
    | "hacker_networking_scans"
    | "hardware_requests"
  >;
};

export const GetSignageCountStatsDocument = gql`
  query GetSignageCountStats {
    signageStats {
      participating_hackers
      responses_submitted
      checked_in_attendees
      hacker_networking_scans
      hardware_requests
    }
  }
`;

/**
 * __useGetSignageCountStatsQuery__
 *
 * To run a query within a React component, call `useGetSignageCountStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignageCountStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignageCountStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignageCountStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSignageCountStatsQuery,
    GetSignageCountStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSignageCountStatsQuery,
    GetSignageCountStatsQueryVariables
  >(GetSignageCountStatsDocument, baseOptions);
}
export function useGetSignageCountStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSignageCountStatsQuery,
    GetSignageCountStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSignageCountStatsQuery,
    GetSignageCountStatsQueryVariables
  >(GetSignageCountStatsDocument, baseOptions);
}
export type GetSignageCountStatsQueryHookResult = ReturnType<
  typeof useGetSignageCountStatsQuery
>;
export type GetSignageCountStatsLazyQueryHookResult = ReturnType<
  typeof useGetSignageCountStatsLazyQuery
>;
export type GetSignageCountStatsQueryResult = ApolloReactCommon.QueryResult<
  GetSignageCountStatsQuery,
  GetSignageCountStatsQueryVariables
>;
