import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { hoverStyles, focusStyles, disabledStyles } from "../sharedStyles";

export const buttonStyles: ThemeDefinition["button"] = {
  sharedStyles: css`
    color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    line-height: 29px;

    border: 1px solid ${({ theme }) => theme.globalConstants.color.brandPrimary};
    border-radius: 16px;
    padding: 6px 32px;

    ${hoverStyles}
    ${disabledStyles}
    ${focusStyles}
  `,
  modifiers: {
    primary: css`
      color: ${({ theme }) => theme.globalConstants.color.white};
      background-color: ${({ theme }) =>
        theme.globalConstants.color.brandPrimary};
      &:hover,
      &:focus {
        opacity: 0.7;
        cursor: pointer;
        outline: none;
      }
      &:disabled {
        opacity: 0.5;
      }
    `,
    secondary: css`
      color: ${({ theme }) => theme.globalConstants.color.brandPrimary};
      border: 1px solid
        ${({ theme }) => theme.globalConstants.color.brandPrimary};
      background-color: ${({ theme }) => theme.globalConstants.color.white};
      &:hover,
      &:focus {
        background-color: ${({ theme }) =>
          theme.globalConstants.color.borderFocusLight};
        cursor: pointer;
        outline: none;
      }
      &:disabled {
        opacity: 0.5;
      }
    `,
    altBlue: css`
      background-color: ${({ theme }) =>
        theme.globalConstants.color.brandPrimary} !important;
    `,
    error: css`
      color: ${({ theme }) => theme.globalConstants.color.textDanger};
      border-color: ${({ theme }) => theme.globalConstants.color.textDanger};
    `,
    confirm: css`
      background-color: ${({ theme }) =>
        theme.globalConstants.color.backgroundSuccess};
      color: ${({ theme }) => theme.globalConstants.color.white};
      border-color: ${({ theme }) =>
        theme.globalConstants.color.backgroundSuccess};
    `,
    square: css`
      border-radius: 5px;
    `,
    radio: css`
      position: absolute;
      padding: 8px;
      border: 2px solid ${({ theme }) => theme.globalConstants.color.white};
      border-radius: 50%;
    `,
    radio_selected: css`
      background-color: ${({ theme }) => theme.globalConstants.color.textDark};
    `,
    compact: css`
      padding: 1px 19px;
      line-height: 1.5;
      font-size: 10px !important;
      border-radius: 4px !important;
    `,
    lesspadding: css`
      padding: 10px 24px;
    `,
    nowrap: css`
      white-space: nowrap;
    `,
    lessOpaqueDisabled: css`
      &:disabled {
        filter: none;
        opacity: 0.6;
      }
    `,
  },
};
