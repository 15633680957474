import { Text } from "@hackthenorth/north";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useGetSignageAnswerFrequencyQuery } from "..//graphql/getSignageAnswerFrequency.generated";

interface BarGraphProps {
  title: string;
  xLabel: string;
  yLabel: string;
  max: number;
}

interface FrequencyData {
  value: number | string;
  frequency: number;
}

interface JsonDataItem {
  value: number | string;
  frequency: number;
}

const BarGraph: React.FC<BarGraphProps> = ({ title, xLabel, yLabel, max }) => {
  const { data, loading, error, refetch } = useGetSignageAnswerFrequencyQuery({
    variables: {
      title: title,
    },
  });

  const [chartData, setChartData] = useState<FrequencyData[]>([]);

  useEffect(() => {
    if (data && data.getSignageAnswerFrequency) {
      const dictionary = JSON.parse(data.getSignageAnswerFrequency) as Record<
        string,
        number
      >;

      const aggregatedData: Record<string, number> = {};

      // Initialize all possible values from 0 to max with frequency 0
      for (let i = 0; i < max; i++) {
        aggregatedData[i.toString()] = 0;
      }
      aggregatedData[`${max}+`] = 0;

      // Aggregate existing data
      for (const [key, frequency] of Object.entries(dictionary)) {
        const numericValue = Number(key);
        const bucketKey =
          numericValue > max ? `${max}+` : `${Math.max(numericValue, 0)}`;
        aggregatedData[bucketKey] =
          (aggregatedData[bucketKey] || 0) + frequency;
      }

      // Convert to array format for Recharts
      const formattedData: FrequencyData[] = Object.entries(aggregatedData).map(
        ([value, frequency]) => ({ value, frequency })
      );

      setChartData(formattedData);
    }
  }, [max, data]);

  useEffect(() => {
    // Set up a refetch interval
    const intervalId = setInterval(() => {
      refetch();
    }, 10000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [refetch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data.</p>;

  return (
    <div>
      <Text mods="heading h1 bold">{title}</Text>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="value"
            label={{ value: xLabel, position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{ value: yLabel, angle: -90, position: "insideLeft" }}
          />
          <Tooltip />
          <Bar dataKey="frequency" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
