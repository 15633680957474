/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetCompanyNameQueryVariables = Types.Exact<{
  companyId: Types.Scalars["Float"];
}>;

export type GetCompanyNameQuery = { company: Pick<Types.Company, "name"> };

export const GetCompanyNameDocument = gql`
  query GetCompanyName($companyId: Float!) {
    company(id: $companyId) {
      name
    }
  }
`;

/**
 * __useGetCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >(GetCompanyNameDocument, baseOptions);
}
export function useGetCompanyNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >(GetCompanyNameDocument, baseOptions);
}
export type GetCompanyNameQueryHookResult = ReturnType<
  typeof useGetCompanyNameQuery
>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<
  typeof useGetCompanyNameLazyQuery
>;
export type GetCompanyNameQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyNameQuery,
  GetCompanyNameQueryVariables
>;
