import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Operation,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getAccessToken } from "@hackthenorth/north";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import omitDeep from "omit-deep-lodash";

import { FieldWithAnswer } from "src/api/types.generated";
import { IS_PRODUCTION } from "src/shared/utils/env";

const getClientUrl = () =>
  IS_PRODUCTION
    ? "https://api.hackthenorth.com/v3/graphql"
    : "https://staging-api.hackthenorth.com/v3/graphql";

const request = (operation: Operation) => {
  const token = getAccessToken();
  operation.setContext({
    headers: {
      authorization: token ?? "",
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

/**
 * Removes the __typename property from request object when firing off mutations
 * Source: https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-552383196
 */
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ["__typename"]; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query);
  if (def && "operation" in def && def.operation === "mutation") {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    requestLink,
    cleanTypenameLink,
    createUploadLink({
      uri: getClientUrl(),
      headers: {
        "Apollo-Require-Preflight": "true",
      },
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      FieldWithAnswer: {
        // DO NOT TOUCH THIS
        // `FieldWithAnswer`s from different claims have the same `field.id`, but contain different `field.answer`'s so we need to tell the cache they're different
        keyFields: (field) => (field as FieldWithAnswer).answer?.id?.toString(),
      },
    },
  }),
});
