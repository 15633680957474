import { Text } from "@hackthenorth/north";
import { Card } from "north.js";
import React from "react";
import { useEffect } from "react";
import { styled } from "twin.macro";

import { BlueCheckmarkIcon } from "src/static/icons";

type TProps = {
  name: string;
  logo?: string | null;
  isSelected: boolean | null;
  onClick: () => void;
  disabled: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function usePreloadImg(img: React.ReactElement<HTMLImageElement> | undefined) {
  const src = img?.props?.src;
  useEffect(() => {
    if (src != null) {
      new Image().src = src;
    }
  }, [src]);
}

const CompanyCard: React.FC<TProps> = ({
  name,
  logo,
  isSelected,
  onClick,
  disabled,
}) => {
  // preload images
  //   usePreloadImg(logo);
  return (
    <Container isSelected={!!isSelected} disabled={!!disabled}>
      <CardWrapper onClick={onClick} onKeyDown={onClick}>
        {isSelected && <IconWrapper width={12} />}
        {logo ? (
          <LogoImg src={logo} alt={name} />
        ) : (
          <StyledText mods="bold textBody">{name}</StyledText>
        )}
      </CardWrapper>
    </Container>
  );
};

const StyledText = styled(Text)`
  font-size: 24px !important;
`;

const LogoImg = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

const CardWrapper = styled(Card)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 113px;
  padding: 0px !important;
`;

const Container = styled.div<{ isSelected: boolean; disabled: boolean }>`
  border-radius: 10px;
  ${({ isSelected, theme }) =>
    isSelected
      ? `border: 2px solid ${theme.globalConstants.color.textPrimary}`
      : `border: 2px solid ${theme.globalConstants.color.transparent}`};
  &:hover {
    cursor: pointer;
    ${({ isSelected, theme }) =>
      isSelected
        ? `border: 2px solid ${theme.globalConstants.color.textPrimary}`
        : `border: 2px solid ${theme.globalConstants.color.borderGray}`};
  }
  ${({ disabled, theme }) =>
    disabled &&
    `
    &:hover {
      cursor: not-allowed;
      border: 2px solid ${theme.globalConstants.color.transparent};
    }
  `};
`;

const IconWrapper = styled(BlueCheckmarkIcon)`
  top: 8px;
  right: 12px;
  position: absolute;
`;

export default CompanyCard;
