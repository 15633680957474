/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetMentorshipIndividualQueryVariables = Types.Exact<{
  userId: Types.Scalars["String"];
}>;

export type GetMentorshipIndividualQuery = {
  claims: Array<
    Pick<Types.Claim, "id" | "name" | "stage_id"> & {
      fields: Array<
        Pick<
          Types.FieldWithAnswer,
          "id" | "field_type" | "slug" | "valid_values" | "updated_at"
        > & {
          answer?: Types.Maybe<
            Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
          >;
        }
      >;
    }
  >;
};

export const GetMentorshipIndividualDocument = gql`
  query GetMentorshipIndividual($userId: String!) {
    claims(
      where: {
        pipeline: {
          is: { slug: { equals: "hackthenorth2023-mentorship-profiles" } }
        }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "user_id" } } } }
              { value: { equals: $userId } }
            ]
          }
        }
      }
    ) {
      id
      name
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetMentorshipIndividualQuery__
 *
 * To run a query within a React component, call `useGetMentorshipIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMentorshipIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMentorshipIndividualQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMentorshipIndividualQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMentorshipIndividualQuery,
    GetMentorshipIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMentorshipIndividualQuery,
    GetMentorshipIndividualQueryVariables
  >(GetMentorshipIndividualDocument, baseOptions);
}
export function useGetMentorshipIndividualLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMentorshipIndividualQuery,
    GetMentorshipIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMentorshipIndividualQuery,
    GetMentorshipIndividualQueryVariables
  >(GetMentorshipIndividualDocument, baseOptions);
}
export type GetMentorshipIndividualQueryHookResult = ReturnType<
  typeof useGetMentorshipIndividualQuery
>;
export type GetMentorshipIndividualLazyQueryHookResult = ReturnType<
  typeof useGetMentorshipIndividualLazyQuery
>;
export type GetMentorshipIndividualQueryResult = ApolloReactCommon.QueryResult<
  GetMentorshipIndividualQuery,
  GetMentorshipIndividualQueryVariables
>;
